import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
//import { withStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import RadioSelection from "./components/RadioSelection";
import { CXContext } from "./context/Context";
import PhaseButtons from "./PhaseButtons";
import ExpansionSelection from "./components/ExpansionSelection";
import Typography from "@material-ui/core/Typography";
import CardSelection from "./components/CardSelection";

import { pageQuestionObject, checkIsDisabled } from "./utils";
import { payerList } from "./constants";
import { formLabel } from "./styles";

const styles = () => ({
  root: {
    marginBottom: 80,
    display: "flex"
  },
  formLabel: formLabel
});

const useStyles = makeStyles(styles);

const Maksaja = ({ pageQuestion }) => {
  const [state, setState] = useState({});

  const context = useContext(CXContext);
  const { mainJson } = context;

  const handleDataUpdate = (label, values) => {
    const { subTasks } = pageQuestionObject(values, payerList);
    let nextState;
    if (!!subTasks) {
      nextState = { [label]: values };
    } else {
      nextState = { ...state, [label]: values };
    }
    setState(nextState);
  };

  const { subTasks, question, component } = pageQuestionObject(
    state[pageQuestion],
    payerList
  );

  useEffect(() => {
    if (mainJson[pageQuestion]) {
      const prevState = mainJson[pageQuestion];

      setState(prevState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let requiredFields = [pageQuestion, question];

  const isDisabled =
    component !== "CardSelection" && !!component
      ? false
      : checkIsDisabled(requiredFields, state);

  const classes = useStyles();
  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <FormControl component="fieldset" className={classes.formControl}>
          <FormLabel classes={{ root: classes.formLabel }} component="legend">
            {pageQuestion}
          </FormLabel>
          <RadioSelection
            currentValue={state[pageQuestion]}
            onSelect={value => handleDataUpdate(pageQuestion, value)}
            options={payerList}
          />
        </FormControl>
      </Grid>
      {
        <Grid item xs={12}>
          {!!subTasks && (
            <ExpansionSelection
              content={
                component === "CardSelection" ? (
                  <Grid container spacing={16}>
                    {subTasks.map(task => {
                      return (
                        <CardSelection
                          styles={{ maxWidth: 200 }}
                          onSelect={value =>
                            handleDataUpdate(question, task.value)
                          }
                          isSelected={task["value"] === state[question]}
                          image={task.img}
                          key={task.value}
                          id={task.value}
                          title={task.value}
                          description={""}
                        />
                      );
                    })}
                  </Grid>
                ) : (
                  <Grid
                    container
                    nowrap
                    direction="column"
                    justify="flex-start"
                    alignItems="stretch"
                  >
                    {subTasks.map(task => (
                      <>
                        <h3>{task.question}</h3>
                        <Typography>{task.value}</Typography>
                      </>
                    ))}
                  </Grid>
                )
              }
            />
          )}
        </Grid>
      }
      <PhaseButtons
        isDisabled={isDisabled}
        formValues={{ [pageQuestion]: { ...state } }}
      />
    </Grid>
  );
};

Maksaja.propTypes = {
  classes: PropTypes.object.isRequired
};

export default Maksaja;
