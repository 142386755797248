import React, { useContext } from "react";
import { withStyles } from "@material-ui/core/styles";
import { CXContext } from "./context/Context";
import {
  QUESTION_AIKATAULU,
  QUESTION_SIJAINTI,
  QUESTION_TOIMTIUS,
  QUESTION_AUTON_TIEDOT,
  QUESTION_YHTEYSTIEDOT,
  QUESTION_YHTEENVETO,
  QUESTION_MIKA_VIKA,
  QUESTION_KUKA_MAKSAA
} from "./constants";

import Aloitus from "./1-Aloitus";
import Seuraava from "./2-Seuraava";
import Aikataulu from "./3-Aikataulu";
import Sijainti from "./4-Sijainti";
import Toimitus from "./5-Toimitus";
import Maksaja from "./6-Maksaja";
import AutonTiedot from "./6-AutonTiedot";
import Yhteystiedot from "./7-Yhteystiedot";
import Yhteenveto from "./8-Yhteenveto";

const GeneratePages = props => {
  const { t } = props;
  const context = useContext(CXContext);
  const { step } = context;

  const getStepContent = activeStep => {
    switch (activeStep) {
      case 0:
        return <Aloitus />;
      case 1:
        return <Seuraava pageQuestion={t(QUESTION_MIKA_VIKA)} />;
      case 2:
        return <Aikataulu pageQuestion={t(QUESTION_AIKATAULU)} />;
      case 3:
        return <Sijainti pageQuestion={t(QUESTION_SIJAINTI)} />;
      case 4:
        return <Toimitus pageQuestion={t(QUESTION_TOIMTIUS)} />;
      case 5:
        return <Maksaja pageQuestion={t(QUESTION_KUKA_MAKSAA)} />;
      case 6:
        return <AutonTiedot pageQuestion={t(QUESTION_AUTON_TIEDOT)} />;
      case 7:
        return <Yhteystiedot pageQuestion={t(QUESTION_YHTEYSTIEDOT)} />;
      case 8:
        return <Yhteenveto pageQuestion={t(QUESTION_YHTEENVETO)} />;
      default:
        throw new Error("Unknown step");
    }
  };

  return <>{getStepContent(step)}</>;
};

export default withStyles()(GeneratePages);
