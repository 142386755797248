import React, { useState } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
  root: {
    width: "100%"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  }
});

const ExpansionSelection = props => {
  const { classes, title, content } = props;

  return (
    <div className={classes.root}>
      <ExpansionPanel expanded={true}>
        {!!title && (
          <ExpansionPanelSummary>
            <Typography className={classes.heading}>{title}</Typography>
          </ExpansionPanelSummary>
        )}
        <ExpansionPanelDetails>{content}</ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
};

ExpansionSelection.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string,
  content: PropTypes.node
};

export default withStyles(styles)(ExpansionSelection);
