import React, { useState } from "react";
import Paper from "@material-ui/core/Paper";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import SingleTextField from "../components/SingleTextField";
import {
  vehicleRegNoLabelValue,
  insuranceCompanyLabelValue,
  vehicleOwnerLabelValue,
  vehicleHolderLabelValue,
  vehicleBrandLabelValue,
  vehicleModelLabelValue,
  VEHICLE_INFORMATION
} from "../constants";

import { registrationPlateParser, firstLetterToUpperCaseParse } from "../utils";

const SectionVehicleInformation = ({ classes, title, onChange }) => {
  const [state, setState] = useState({});
  const handleChange = (label, value) => {
    const nextState = { ...state, [label]: value };
    setState(nextState);
    onChange(VEHICLE_INFORMATION, nextState);
  };
  return (
    <div>
      <FormLabel classes={{ root: classes.formLabel }} component="legend">
        {title}
      </FormLabel>
      <FormControl className={classes.formControl}>
        <SingleTextField
          parser={registrationPlateParser}
          required={false}
          onChange={handleChange}
          id={vehicleRegNoLabelValue.value}
          label={vehicleRegNoLabelValue.label}
        />
      </FormControl>
      <FormControl className={classes.formControl}>
        <SingleTextField
          parser={firstLetterToUpperCaseParse}
          //currentValue={state[SUKUNIMI]}
          required={false}
          onChange={handleChange}
          label={insuranceCompanyLabelValue.label}
          id={insuranceCompanyLabelValue.value}
        />
      </FormControl>
      <FormControl className={classes.formControl}>
        <SingleTextField
          //currentValue={state[PUHELINNUMERO]}

          parser={firstLetterToUpperCaseParse}
          required={false}
          onChange={handleChange}
          label={vehicleOwnerLabelValue.label}
          id={vehicleOwnerLabelValue.value}
        />
      </FormControl>
      <FormControl className={classes.formControl}>
        <SingleTextField
          required={false}
          //currentValue={state['A']}
          onChange={handleChange}
          label={vehicleHolderLabelValue.label}
          id={vehicleHolderLabelValue.value}
          parser={firstLetterToUpperCaseParse}
        />
      </FormControl>
      <FormControl className={classes.formControl}>
        <SingleTextField
          required={false}
          //currentValue={state['A']}
          parser={firstLetterToUpperCaseParse}
          onChange={handleChange}
          label={vehicleBrandLabelValue.label}
          id={vehicleBrandLabelValue.value}
        />
      </FormControl>
      <FormControl className={classes.formControl}>
        <SingleTextField
          required={false}
          //currentValue={state['A']}
          parser={firstLetterToUpperCaseParse}
          onChange={handleChange}
          label={vehicleModelLabelValue.label}
          id={vehicleModelLabelValue.value}
        />
      </FormControl>
    </div>
  );
};

export default SectionVehicleInformation;
