import React, { useState, useContext, useEffect } from "react";
import { prop } from "ramda";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { CXContext } from "./context/Context";
import Map from "./components/Map";
import GoogleAutoComplete from "./components/GoogleAutoComplete";
import GeoLocation from "./components/GeoLocation";
import Buttoni from "./components/Buttoni";
import Comment from "./components/Comment";
import MessageModal from "./components/MessageModal";
import PhaseButtons from "./PhaseButtons";
import {
  checkIsDisabled,
  getPartners,
  getPartnersByCompanyId,
  fetchClosestPartner,
  isInRange,
} from "./utils";

import {
  PLACEHOLDER_CAR_LOCATION,
  ALOITA_PAIKANNUS,
  LISÄTIETOA,
  MARKER,
  STREET_ADDRESS,
  IN_RANGE,
} from "./constants";

import { formLabel } from "./styles";

const requiredFields = [MARKER, IN_RANGE];

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  formLabel: formLabel,
  formControl: {
    margin: theme.spacing.unit * 3,
  },
  group: {
    margin: `${theme.spacing.unit}px 0`,
  },
  comment: {
    width: "100%",
  },
});

const AutonTiedot = ({ classes, pageQuestion }) => {
  const [state, setState] = useState({ [IN_RANGE]: true });
  const [partnerState, setPartnerState] = useState({
    partnerList: [],
  });

  const [enableGeoLocation, handleEnableGeoLocation] = useState(false);

  const context = useContext(CXContext);
  const { mainJson } = context;

  const handleDataUpdate = (label, values) => {
    const nextState = { ...state, [label]: values };
    setState(nextState);
  };

  useEffect(() => {
    const run = async () => {
      let prevState;

      if (mainJson[pageQuestion]) {
        prevState = mainJson[pageQuestion];
        setState({ ...prevState });
      }

      const partners = await getPartners(); //await getPartnersByCompanyId(2040);
      const data = partners.data ? partners.data : [];
      setPartnerState({ ...partnerState, partnerList: data });
    };

    run();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGeoLocation = () => {
    handleEnableGeoLocation(!enableGeoLocation);
  };

  const handleAddress = async (geoLatLng, streetAddress) => {
    const { distance, partner } = await fetchClosestPartner(
      geoLatLng,
      partnerState.partnerList
    );

    const partnerRange = prop("range", partner);

    setState({
      ...state,
      [MARKER]: { ...geoLatLng },
      [STREET_ADDRESS]: streetAddress,
      [IN_RANGE]: isInRange(partnerRange, distance),
    });
  };

  const isDisabled = checkIsDisabled(requiredFields, state);

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <FormLabel classes={{ root: classes.formLabel }} component="legend">
          {pageQuestion}
        </FormLabel>
      </Grid>
      <Grid container direction={"column"} xs={12} sm={12} md={6} spacing={6}>
        <GoogleAutoComplete
          placeholder={PLACEHOLDER_CAR_LOCATION}
          onAddressSelect={handleAddress}
          loadingElement={<div style={{ height: `100%` }} />}
          googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBZa_nTG1bxGmQCf3s_qVNMFIKPvz0oLkw&v=3.exp&libraries=geometry,drawing,places"
        />
        <FormLabel
          style={{ marginTop: 20, fontWeight: "bold" }}
          component="legend"
        >
          Tai valitse
        </FormLabel>
        <Buttoni onChange={handleGeoLocation}>{ALOITA_PAIKANNUS}</Buttoni>
        <Comment
          value={state[LISÄTIETOA]}
          className={classes.comment}
          label={LISÄTIETOA}
          onChange={handleDataUpdate}
          title="Lisää kuvaus (esim parkkihalli, sisäpiha)"
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <MessageModal
          isOpen={!state[IN_RANGE]}
          content={
            <p>
              Ikävä kyllä meidän koneisto ei sinun luo yllä, mutta voit soittaa
              09 350 8550 niin välitämme sinut lähimmlle kumppanillemme.
            </p>
          }
          header={"Sijainti on liian kaukana"}
        />
        <>
          {!!enableGeoLocation && (
            <GeoLocation
              isEnabled={enableGeoLocation}
              onChange={handleAddress}
            />
          )}
          {state[MARKER] && (
            <Map
              userMarker={state[MARKER]}
              center={state[MARKER]}
              zoom
              isMarkerShown={!!state[MARKER]}
              googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBZa_nTG1bxGmQCf3s_qVNMFIKPvz0oLkw&v=3.exp&libraries=geometry,drawing,places"
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={<div style={{ height: `400px` }} />}
              mapElement={<div style={{ height: `100%` }} />}
            />
          )}
        </>
      </Grid>
      <PhaseButtons
        isDisabled={isDisabled}
        formValues={{
          [pageQuestion]: {
            ...state,
          },
        }}
      />
    </Grid>
  );
};

AutonTiedot.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AutonTiedot);
