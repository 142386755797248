import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import ExpansionSelection from "./components/ExpansionSelection";
import Grid from "@material-ui/core/Grid";
import RadioSelection from "./components/RadioSelection";
import * as R from "ramda";
import { CXContext } from "./context/Context";
import PhaseButtons from "./PhaseButtons";
import MultiTextField from "./components/MultiTextField";
import { problemList, RADIO_FIELD, MULTI_TEXT_FIELD } from "./constants";

import { getSubtasks, pageQuestionObject, checkIsDisabled } from "./utils";

import { formLabel } from "./styles";

const styles = theme => ({
  root: {
    display: "flex"
  },
  formControl: {
    margin: theme.spacing.unit * 3
  },
  formLabel: formLabel
});

const TOW_REASON = "Syy";
const additionalInformation = "Kerro omin sanoin enemmän";

const LIST = problemList;

const Seuraava = ({ classes, pageQuestion }) => {
  const [state, setState] = useState({});

  const context = useContext(CXContext);
  const { path, mainJson } = context;

  const selection = R.find(item => item.key === path, LIST);
  const title = R.prop("title", selection);
  const subTasks = R.prop("subTask", selection);
  const question = R.prop("question", selection);

  let requiredFields = [question];
  let isDisabled = checkIsDisabled(requiredFields, state);

  useEffect(() => {
    if (mainJson[pageQuestion]) {
      const prevState = mainJson[pageQuestion];
      setState(prevState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDataUpdate = (label, values) => {
    const { subTasks } = pageQuestionObject(values, selection);
    let nextState = { [TOW_REASON]: title };
    if (!!subTasks) {
      nextState = { ...nextState, [label]: values };
    } else {
      nextState = { ...nextState, ...state, [label]: values };
    }

    setState(nextState);
  };

  const renderContent = (question, option) => {
    requiredFields = [...requiredFields, question];
    isDisabled = checkIsDisabled(requiredFields, state);

    let Radio = null;
    let MultiText = null;
    const content = option.reduce(
      (acc, curr) => {
        if (curr.component === RADIO_FIELD) {
          acc.RadioField.push(curr);
        } else if (curr.component === MULTI_TEXT_FIELD) {
          acc.MultiTextField.push(curr);
        }
        return acc;
      },
      { RadioField: [], MultiTextField: [] }
    );

    if (!R.isEmpty(R.prop("RadioField", content))) {
      Radio = (
        <RadioSelection
          currentValue={state[question]}
          onSelect={value => handleDataUpdate(question, value)}
          options={content.RadioField}
        />
      );
    }
    if (!R.isEmpty(R.prop("MultiTextField", content))) {
      MultiText = (
        <MultiTextField
          currentValue={state[additionalInformation]}
          onChange={(label, value) => {
            handleDataUpdate(label, value);
          }}
          label={content.MultiTextField[0].value}
          helperText="Voitteko ilmoittaa mahdollisia lisätietoja virheilmoituksesta?"
        />
      );
    }
    return (
      <>
        {Radio}
        {MultiText}
      </>
    );
  };

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <FormControl component="fieldset" className={classes.formControl}>
          <FormLabel classes={{ root: classes.formLabel }} component="legend">
            {question}
          </FormLabel>
          <br />
          <RadioSelection
            currentValue={state[question]}
            onSelect={value => handleDataUpdate(question, value)}
            options={subTasks}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        {state[question] &&
          selection["subTask"] &&
          (() => {
            const subTask = getSubtasks(subTasks, state[question]);

            const questionSub =
              R.prop("question", subTask) && R.prop("question", subTask);

            const optionsSub =
              R.prop("subTask", subTask) && R.prop("subTask", subTask);

            if (!optionsSub) {
              return;
            }
            return (
              <>
                <FormControl
                  component="fieldset"
                  className={classes.formControl}
                >
                  <FormLabel
                    component="legend"
                    classes={{ root: classes.formLabel }}
                  >
                    {questionSub}
                  </FormLabel>
                </FormControl>
                <ExpansionSelection
                  content={renderContent(questionSub, optionsSub)}
                />
              </>
            );
          })()}
      </Grid>
      <PhaseButtons
        isDisabled={isDisabled}
        formValues={{
          [pageQuestion]: {
            ...state
          }
        }}
      />
    </Grid>
  );
};

Seuraava.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Seuraava);
