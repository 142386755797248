import React, { useState } from "react";
import Paper from "@material-ui/core/Paper";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import DropDownSelect from "../components/DropDownSelect";
import {
  priorityLabelValue,
  priorityList,
  orderVisibilityLabelValue,
  orderVisibilityList,
  ORDER_SETTINGS
} from "../constants";

const SectiOrderSettings = ({ classes, title, onChange, clear }) => {
  const [state, setState] = useState({});
  const handleChange = (label, value) => {
    const nextState = { ...state, [label]: value };
    setState(nextState);
    onChange(ORDER_SETTINGS, nextState);
  };
  return (
    <div>
      <FormLabel classes={{ root: classes.formLabel }} component="legend">
        {title}
      </FormLabel>
      <DropDownSelect
        label={priorityLabelValue.label}
        id={priorityLabelValue.value}
        onChange={handleChange}
        options={priorityList}
      />
      <DropDownSelect
        label={orderVisibilityLabelValue.label}
        id={orderVisibilityLabelValue.value}
        options={orderVisibilityList}
        onChange={handleChange}
      />
    </div>
  );
};

export default SectiOrderSettings;
