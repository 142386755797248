import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ExpansionSelection from "./ExpansionSelection";

const styles = theme => {
  return {
    formLabel: { fontSize: 24, color: "red" },
    root: {
      display: "flex",
      fontSize: 40
    },
    radio: {
      "&$checked": {
        color: "#4B8DF8"
      }
    },
    checked: {},
    formControlLabel: {
      fontSize: 18
    }
  };
};

const RadioSelection = props => {
  const [value, handleValue] = useState(null);

  const { onSelect, classes, options, currentValue, title } = props;

  useEffect(() => {
    if (!!currentValue) {
      handleValue(currentValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentValue]);

  const handleChange = event => {
    const value = event.target.value;
    handleValue(value);
    onSelect(value);
  };

  return (
    <div>
      <FormControl component="fieldset">
        <FormGroup>
          <RadioGroup
            className={classes.group}
            value={value}
            onChange={handleChange}
          >
            {!!options &&
              options.map(item => {
                const checked = value == item.value;
                return (
                  <FormControlLabel
                    classes={{ label: classes.formControlLabel }}
                    value={item.value}
                    control={
                      <Radio
                        classes={{
                          root: classes.radio,
                          checked: classes.checked
                        }}
                        checked={checked}
                      />
                    }
                    label={item.value}
                  />
                );
              })}
          </RadioGroup>
        </FormGroup>
      </FormControl>
    </div>
  );
};

RadioSelection.propTypes = {
  title: null
};

export default withStyles(styles)(RadioSelection);
