import React, { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  Circle,
  InfoWindow
} from "react-google-maps";
import { path, prop, propOr } from "ramda";

const Map = withScriptjs(
  withGoogleMap(props => {
    const mapRef = useRef(null);
    let {
      center,
      partnerMarkers,
      partners,
      userMarker,
      onSelectPartner,
      zoom
    } = props;

    const [state, setState] = useState({ showTooltip: false });

    const handleMouseOver = i => {
      setState({
        ...state,
        showTooltip: i
      });
    };
    const handleMouseExit = () => {
      setState({
        ...state,
        showTooltip: null
      });
    };

    useEffect(() => {
      const markers = partners.map((partner, index) => {
        return getPartnerCoordinates(partner);
      });
      setBounds(markers);
    }, []);

    useEffect(() => {
      if (!prop("lat", userMarker) | !prop("lat", center)) {
        return;
      }
      setBounds([center, userMarker]);
    }, [userMarker, center]);

    const getPartnerCoordinates = partner => {
      const markerCoordinates = {
        lat: path(["coordinates", "x"], partner),
        lng: path(["coordinates", "y"], partner)
      };
      return markerCoordinates;
    };

    const setBounds = markers => {
      let bounds = new window.google.maps.LatLngBounds();
      markers.forEach(marker => {
        bounds.extend(marker);
      });
      mapRef.current.fitBounds(bounds);
    };

    return (
      <GoogleMap ref={mapRef} zoom={zoom} center={center}>
        {props.isMarkerShown && (
          <>
            {partners.map((partner, index) => {
              const markerCoordinates = getPartnerCoordinates(partner);
              const rangeExtent = propOr(50, "range", partner) * 1000;

              return (
                <>
                  <Marker
                    icon={{
                      url:
                        "https://visualpharm.com/assets/990/Tow%20Truck-595b40b85ba036ed117dac99.svg",
                      scaledSize: new window.google.maps.Size(30, 30)
                    }}
                    position={markerCoordinates}
                    onMouseOver={() => handleMouseOver(index)}
                    onMouseOut={handleMouseExit}
                    onClick={() => {
                      onSelectPartner("", prop("_id", partner));
                    }}
                  >
                    {state.showTooltip === index && (
                      <InfoWindow>
                        <>
                          <p>{prop("name", partner)}</p>
                          <p>{prop("address", partner)}</p>
                        </>
                      </InfoWindow>
                    )}
                  </Marker>
                  <Circle
                    center={markerCoordinates}
                    radius={rangeExtent}
                    options={{
                      fillColor: `red`,
                      strokeWeight: 0
                    }}
                  />
                </>
              );
            })}
          </>
        )}
        {!!userMarker && <Marker position={userMarker} />}
      </GoogleMap>
    );
  })
);

Map.propTypes = {
  marker: PropTypes.object,
  anotherMarker: PropTypes.object,
  isMarkerShown: PropTypes.bool,
  googleMapURL: PropTypes.string,
  loadingElement: PropTypes.element,
  containerElement: PropTypes.element,
  mapElement: PropTypes.element,
  onSelectPartner: PropTypes.func,
  zoom: PropTypes.number
};

Map.defaultProps = {
  marker: null,
  anotherMarker: null,
  center: { lat: 60.25247, lng: 24.94979 },
  isMarkerShown: false,
  partners: [],
  userMarker: null,
  onSelectPartner: e => e,
  zoom: 8
};
export default Map;
