import React, { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import SingleTextField from "../components/SingleTextField";
import {
  CUSTOMER_SETTINGS,
  CUSTOMER_TYPE,
  CUSTOMER_TYPE_COMPANY,
  CUSTOMER_TYPE_CONSUMER,
  companyCompanyNameLabelValue,
  companyContactPersonLabelValue,
  companyPhoneLabelValue,
  companyContactPersonEmailLabelValue,
  userLastNameLabelValue,
  userFirstNameLabelValue,
  userPhoneNumberLabelValue,
  userEmailLabelValue
} from "../constants";

import { firstLetterToUpperCaseParse } from "../utils";

const initialState = {
  [CUSTOMER_TYPE]: CUSTOMER_TYPE_CONSUMER
};

const SectionCustomerInformation = ({
  classes,
  title,
  onChange,
  onChangeCustumerType
}) => {
  const [state, setState] = useState({});

  useEffect(() => {
    setState(initialState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (label, value) => {
    const nextState = { ...state, [label]: value };
    setState(nextState);
    onChange(CUSTOMER_SETTINGS, nextState);
  };
  return (
    <div>
      <FormLabel classes={{ root: classes.formLabel }} component="legend">
        {"TILAAJAN TIEDOT"}
      </FormLabel>
      <Grid container direction="row" alignContent="center" alignItems="center">
        <Switch
          checked={state[CUSTOMER_TYPE] === CUSTOMER_TYPE_COMPANY}
          onChange={() => {
            if (state[CUSTOMER_TYPE] === CUSTOMER_TYPE_CONSUMER) {
              handleChange(CUSTOMER_TYPE, CUSTOMER_TYPE_COMPANY);
              onChangeCustumerType(CUSTOMER_TYPE_COMPANY);
            } else {
              handleChange(CUSTOMER_TYPE, CUSTOMER_TYPE_CONSUMER);
              onChangeCustumerType(CUSTOMER_TYPE_CONSUMER);
            }
          }}
          value={state[CUSTOMER_TYPE]}
          inputProps={{ "aria-label": "secondary checkbox" }}
        />
        <FormLabel component="legend">{"Yrityskäyttäjä"}</FormLabel>
      </Grid>
      {state[CUSTOMER_TYPE] === CUSTOMER_TYPE_CONSUMER ? (
        <>
          <FormControl className={classes.formControl}>
            <SingleTextField
              required={true}
              //currentValue={state['A']}
              parser={firstLetterToUpperCaseParse}
              onChange={handleChange}
              label={userLastNameLabelValue.label}
              id={userLastNameLabelValue.value}
              //helperText="Ei pakollinen. Sähköpostia käytetään vain tilausvahvistuksen lähettämiseen."
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <SingleTextField
              required={true}
              //currentValue={state['A']}
              parser={firstLetterToUpperCaseParse}
              onChange={handleChange}
              label={userFirstNameLabelValue.label}
              id={userFirstNameLabelValue.value}
              //helperText="Ei pakollinen. Sähköpostia käytetään vain tilausvahvistuksen lähettämiseen."
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <SingleTextField
              required={true}
              //currentValue={state['A']}
              //validator={phoneNumberValidator}
              onChange={handleChange}
              label={userPhoneNumberLabelValue.label}
              id={userPhoneNumberLabelValue.value}
              //helperText="Ei pakollinen. Sähköpostia käytetään vain tilausvahvistuksen lähettämiseen."
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <SingleTextField
              required={false}
              //currentValue={state['A']}
              onChange={handleChange}
              label={userEmailLabelValue.label}
              id={userEmailLabelValue.value}
              //helperText="Ei pakollinen. Sähköpostia käytetään vain tilausvahvistuksen lähettämiseen."
            />
          </FormControl>
        </>
      ) : (
        <>
          <FormControl className={classes.formControl}>
            <SingleTextField
              required={true}
              //currentValue={state['A']}
              parser={firstLetterToUpperCaseParse}
              onChange={handleChange}
              label={companyCompanyNameLabelValue.label}
              id={companyCompanyNameLabelValue.value}
              //helperText="Ei pakollinen. Sähköpostia käytetään vain tilausvahvistuksen lähettämiseen."
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <SingleTextField
              required={true}
              //currentValue={state['A']}
              parser={firstLetterToUpperCaseParse}
              onChange={handleChange}
              label={companyContactPersonLabelValue.label}
              id={companyContactPersonLabelValue.value}
              //helperText="Ei pakollinen. Sähköpostia käytetään vain tilausvahvistuksen lähettämiseen."
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <SingleTextField
              required={true}
              //currentValue={state['A']}
              //validator={phoneNumberValidator}
              onChange={handleChange}
              label={companyPhoneLabelValue.label}
              id={companyPhoneLabelValue.value}
              //helperText="Ei pakollinen. Sähköpostia käytetään vain tilausvahvistuksen lähettämiseen."
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <SingleTextField
              required={false}
              //currentValue={state['A']}
              onChange={handleChange}
              label={companyContactPersonEmailLabelValue.label}
              id={companyContactPersonEmailLabelValue.value}
              //helperText="Ei pakollinen. Sähköpostia käytetään vain tilausvahvistuksen lähettämiseen."
            />
          </FormControl>
        </>
      )}
    </div>
  );
};

export default SectionCustomerInformation;
