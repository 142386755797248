import Geocode from "react-geocode";
import ReactGA from "react-ga";
import { GOOGLE_API_KEY } from "./constants";
import * as R from "ramda";
import { isEmpty, prop, find, propOr, is } from "ramda";
import { problemList, API_URL, GA_ROOT_PATH } from "./constants";
import axios from "axios";

export const createNewPartner = async partner => {
  return await axios(`${API_URL}/api/partners`, {
    method: "POST",
    withCredentials: true,
    data: partner
  })
    .then(response => R.prop("data", response))
    .catch(err => {
      console.log("There was an error: ", err);
    });
};

export const deletePartnerById = async partnerId => {
  const response = await axios(`${API_URL}/api/partners/${partnerId}`, {
    method: "delete",
    withCredentials: true
  });
  return response;
};

export const partnerUpdate = async partner => {
  return await axios(`${API_URL}/api/partners`, {
    method: "put",
    withCredentials: true,
    data: partner
  })
    .then(response => R.prop("data", response))
    .catch(err => err);
};

export const isInRange = (partnerRange, distanceToTarget) => {
  if (
    !partnerRange |
    !distanceToTarget |
    !is(Number, partnerRange) |
    !is(Number, distanceToTarget)
  ) {
    console.log(
      !partnerRange,
      !distanceToTarget,
      !is(Number, partnerRange),
      !is(Number, distanceToTarget)
    );
    console.log(
      "Not a good result for the customer: ",
      "partner range:",
      partnerRange,
      "and",
      " distance to target: ",
      distanceToTarget
    );
    return false;
  }
  return partnerRange >= distanceToTarget;
};

export const GAPageViewAction = pageIndex => {
  const GASteps = [
    "aloitus",
    "seuraava",
    "aikataulu",
    "sijainti",
    "toimitus",
    "maksaja",
    "autontiedot",
    "yhteystiedot",
    "yhteenveto"
  ];

  // data-layer alustus, jos sitä ei ole vielä tehty


  const page = !!pageIndex ? `/${GA_ROOT_PATH}/${GASteps[pageIndex]}` : `/${GA_ROOT_PATH}`
  console.log("Google analytics event:", "pageView", page);
  window.dataLayer.push({
    'event': 'Google analytics event',
    'pageView': page
  });

};

export const GACreateNewOrderAction = () => {
  console.log("Google analytics event:", "orderSent");
  ReactGA.event({
    category: "verkkotilaus",
    action: "Tilaus lähetetty"
  });
};

export const generateOptionsLabelValue = (arr, value, label) =>
  arr.map(curr => ({ value: curr[value], label: curr[label] }));

export const generatePartnerOptionsLabelValue = arr =>
  arr.map(curr => ({
    value: curr["_id"],
    label: `${curr["name"]} ${propOr("", "innovoice_branch_name", curr)}`
  }));

export const getPartners = async () => {
  const response = await axios(`${API_URL}/api/partners`, {
    method: "get",
    withCredentials: true
  });
  return response;
};

export const getPartnerById = async partnerId => {
  const response = await axios(`${API_URL}/api/partner/${partnerId}`, {
    method: "get",
    withCredentials: true
  });
  return response;
};

export const getPartnersByCompanyId = async companyId => {
  const response = await axios(
    `${API_URL}/api/partners?innovoice_company_id=${companyId}`,
    {
      method: "get",
      withCredentials: true
    }
  );
  return response;
};

export const fetchClosestPartner = (coordinates, partnerList) => {
  return axios(`${API_URL}/api/partners/closest-partner`, {
    method: "post",
    withCredentials: true,
    data: { ...coordinates, partnerList: partnerList }
  })
    .then(response => {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return error;
    });
};

export const fetchOrders = order => {
  const _id = prop("_id", order);
  const url = !!_id ? `${API_URL}/api/orders/${_id}` : `${API_URL}/api/orders`;
  return axios(url, { method: "get", withCredentials: true })
    .then(response => {
      return response;
    })
    .catch(function (error) {
      console.log(error);
      return error;
    });
};

export const verifyUserAuthenticated = () => {
  return axios(`${API_URL}/auth/isAuthenticated`, {
    method: "get",
    withCredentials: true
  })
    .then(response => {
      console.log({ verifyUserAuthenticate: response });
      return response;
    })
    .catch(err => console.log("Error verifying user", err));
};

export const sendOrder = (data, path = "api") => {
  return axios(`${API_URL}/${path}`, {
    method: "post",
    withCredentials: true,
    data
  })
    .then(response => {
      return response;
    })
    .catch(function (error) {
      console.log(error);
      return error;
    });
};
// set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
Geocode.setApiKey(GOOGLE_API_KEY);

// Enable or disable logs. Its optional.
Geocode.enableDebug();

// Get address from latidude & longitude.
export const getAddressFromLatLng = marker => {
  return Geocode.fromLatLng(marker.lat, marker.lng)
    .then(response => {
      const address = response.results[0].formatted_address;
      return address;
    })
    .catch(error => {
      console.error(error);
    });
};

export const getLatLngFromAddress = text => {
  Geocode.fromAddress(text)
    .then(response => {
      const { lat, lng } = response.results[0].geometry.location;
      return { lat, lng };
    })
    .catch(error => {
      console.error(error);
    });
};

export const getSubtasks = (arr, value) => {
  const task = find(record => record["value"] === value, arr);
  return task;
};

export const checkIsDisabled = (list, state) => {
  return (
    list.length !==
    list.filter(field => !!state[field] && !isEmpty(state[field])).length
  );
};

export const registrationPlateParser = value => {
  let text = value.toUpperCase();
  let a = text;
  let b = "-";
  let position = 3;
  if (a.length > 3 && !a.includes(b)) {
    text = [a.slice(0, position), b, a.slice(position)].join("");
  }
  return text;
};

export const firstLetterToUpperCaseParse = string => {
  if (typeof string !== "string") return "";
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const phoneNumberValidator = value => {
  const numbersRegex = /^[0-9]+$/;
  if (value.match(numbersRegex)) {
    return true;
  } else {
    return false;
  }
};

export const checkResponseType = response => {
  if (typeof response === "string") {
    return response;
  } else if (typeof response === "object") {
    return Object.keys(response)
      .filter(key => !!response[key])
      .join();
  }
};

export const pageQuestionObject = (value, list) => {
  let arr = list;
  let subTasks;
  let question;
  let component;
  const hasSubTasks = prop("subTask", list);

  if (!!hasSubTasks) {
    arr = hasSubTasks;
  } else {
    const selection = find(item => prop("value", item) === value, arr);

    subTasks = prop("subTask", selection);
    question = prop("question", selection);
    component = prop("component", selection);
  }
  return { subTasks, question, component };
};

export const mapCompanyIdsToNames = id => {
  const companies = {
    "2040": "Hinausautokeskus Oy",
    "1268": "Järvenpaan Vika-Auto Oy",
    "6666": "Odottaa vastaanottavaa yritystä"
  };

  return companies[id] ? companies[id] : id;
};

export const mapStatusToIcons = id => {
  const companies = {
    "2040": "Hinausautokeskus Oy",
    "1268": "Järvenpaan Vika-Auto Oy",
    "6666": "Odottaa vastaanottavaa yritystä"
  };

  return companies[id] ? companies[id] : id;
};
