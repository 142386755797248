import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import RadioSelection from "./components/RadioSelection";
import { CXContext } from "./context/Context";
import GoogleAutoComplete from "./components/GoogleAutoComplete";
import PhaseButtons from "./PhaseButtons";
import { checkIsDisabled } from "./utils";
import {
  PLACEHOLDER_CAR_LOCATION,
  MINNE_AUTO_VIEDÄÄN,
  KORJAAMON_SIJAINTI,
  YES,
  NO,
  MARKER,
  STREET_ADDRESS
} from "./constants";

import { formLabel } from "./styles";

const requiredFields = [MINNE_AUTO_VIEDÄÄN];

const styles = theme => ({
  root: {
    display: "flex"
  },
  formControl: {
    margin: theme.spacing.unit * 3
  },
  formLabel: formLabel
});

const Toimitus = ({ classes, pageQuestion }) => {
  const [state, setState] = useState({});

  const context = useContext(CXContext);
  const { mainJson } = context;

  const handleDataUpdate = (label, values) => {
    const nextState = { ...state, [label]: values };
    setState(nextState);
  };

  useEffect(() => {
    if (mainJson[pageQuestion]) {
      const prevState = mainJson[pageQuestion];

      setState(prevState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddress = (geoLatLng, streetAddress) => {
    setState({
      ...state,
      [MARKER]: { ...geoLatLng },
      [STREET_ADDRESS]: streetAddress
    });
  };

  const isDisabled = checkIsDisabled(requiredFields, state);

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <FormControl component="fieldset" className={classes.formControl}>
          <FormLabel classes={{ root: classes.formLabel }} component="legend">
            {MINNE_AUTO_VIEDÄÄN}
          </FormLabel>
          <RadioSelection
            currentValue={state[MINNE_AUTO_VIEDÄÄN]}
            onSelect={value => {
              handleDataUpdate(MINNE_AUTO_VIEDÄÄN, value);
            }}
            options={[
              {
                value: YES
              },
              {
                value: NO
              }
            ]}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        {state[MINNE_AUTO_VIEDÄÄN] === YES && (
          <FormControl component="fieldset" className={classes.formControl}>
            <GoogleAutoComplete
              placeholder={PLACEHOLDER_CAR_LOCATION}
              value={state[KORJAAMON_SIJAINTI]}
              label={KORJAAMON_SIJAINTI}
              onAddressSelect={handleAddress}
              loadingElement={<div style={{ height: `100%` }} />}
              googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBZa_nTG1bxGmQCf3s_qVNMFIKPvz0oLkw&v=3.exp&libraries=geometry,drawing,places"
            />
          </FormControl>
        )}
      </Grid>
      <PhaseButtons
        isDisabled={isDisabled}
        formValues={{
          [pageQuestion]: {
            ...state
          }
        }}
      />
    </Grid>
  );
};

Toimitus.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Toimitus);
