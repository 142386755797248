import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import {
  BrowserRouter as Router,
  Route,
  Link,
  useHistory,
  useLocation
} from "react-router-dom";
import CheckCircle from "@material-ui/icons/CheckCircle";
import CancelRounded from "@material-ui/icons/CancelRounded";
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import StatusIcon from "@material-ui/icons/Cloud";
import BusinessIcon from "@material-ui/icons/Business";
import Typography from "@material-ui/core/Typography";
import { PATH_ADMIN_ORDER_FORM } from "../constants";

import { propOr, prop, path } from "ramda";

const styles = theme => ({
  paper: {
    fontSize: 14,
    fontWeight: "normal",
    fontFamily: "monospace",
    position: "absolute",
    left: "50%",
    top: "40%",
    transform: "translate(-50%, -30%)",
    width: "45%",
    height: "50%",
    backgroundColor: "white",
    textAlign: "center",
    padding: 30
  },
  buttons: {
    zIndex: 9999,
    width: "100%",
    maxHeight: 100,
    display: "flex",
    position: "fixed",
    bottom: 0,
    backgroundColor: "white",
    alignItems: "center",
    marginTop: 5
  },
  button: {
    marginTop: theme.spacing.unit * 3,
    marginLeft: theme.spacing.unit,
    boxShadow: "none"
  },
  buttonContainer: {
    margin: "50px 0",
    width: "100%"
  },
  iconSuccess: {
    color: "green",
    fontSize: 60
  },
  iconError: {
    color: "red",
    fontSize: 60
  }
});

const AdminOrderConfirmationDialog = ({ t, classes }) => {
  const [open, setOpen] = React.useState(true);
  const history = useHistory();

  const handleClose = () => {
    history.push(PATH_ADMIN_ORDER_FORM);
    window.location.reload();
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Tilaus rekisteröity onnistuneesti"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {"Voit seurata tilausta tilauslistalta"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            {t("buttonCreateAnotherOrder")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default withStyles(styles)(AdminOrderConfirmationDialog);
