import React, { useState } from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { ReactComponent as Logo } from "../elk-cg.svg";
import {
  PATH_PAGE_NOT_FOUND,
  PATH_LOGIN,
  PATH_ROOT,
  PATH_ADMIN_ORDER_FORM,
  PATH_ADMIN_ORDER_LIST,
  PATH_ADMIN_PARTNER_LIST,
  API_URL_LOGOUT
} from "../constants";

const styles = () => ({
  menu: {
    boxShadow: "none",
    backgroundColor: "#f70000"
  },
  menuButton: {
    marginRight: 20
  },
  title: {
    marginLeft: 30,
    color: "white",
    flexGrow: 1
  }
});

const ITEM_HEIGHT = 48;

const AdminOrderMenu = props => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const { classes, t } = props;

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Grid xs={12} item>
      <AppBar className={classes.menu} position="static">
        <Toolbar>
          <div style={{ maxWidth: 100 }}>
            <Logo style={{ width: "100%" }} />
          </div>
          <Typography variant="h5" className={classes.title}>
            {t("headerAdminPanel")}
          </Typography>
          <Link
            to={PATH_ADMIN_ORDER_FORM}
            style={{ textDecoration: "none", color: "white" }}
          >
            <Button color="inherit">{t("navOrderForm")}</Button>
          </Link>
          <Link
            to={PATH_ADMIN_ORDER_LIST}
            style={{ textDecoration: "none", color: "white" }}
          >
            <Button color="inherit">{t("navOrderList")}</Button>
          </Link>
          <Link
            to={PATH_ADMIN_PARTNER_LIST}
            style={{ textDecoration: "none", color: "white" }}
          >
            <Button color="inherit">{t("navPartnerList")}</Button>
          </Link>
          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={handleClick}
            style={{ color: "white" }}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="long-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: 200
              }
            }}
          >
            <MenuItem>
              <a
                href={API_URL_LOGOUT}
                style={{ textDecoration: "none", color: "black" }}
              >
                {t("buttonLogout")}
              </a>
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
    </Grid>
  );
};

export default withStyles(styles)(AdminOrderMenu);
