import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Context from "./context/Context";
import PhaseStepper from "./components/PhaseStepper";
import GeneratePages from "./GeneratePages";
import StatusModal from "./components/StatusModal";

import { ReactComponent as Logo } from "./elk-cg.svg";
//import { runGoogleTag } from "./utils";

const getScreenSize = () => {
  return window.innerHeight;
};

const styles = theme => ({
  typography: {
    fontSize: "50px"
  },
  appBar: {
    position: "relative",
    backgroundColor: "#f70000",
    boxShadow: "none",
    height: 60
  },
  layout: {
    flexGrow: 1,
    alignItems: "stretch",
    alignContent: "stretch",
    width: "100%",
    height: `${getScreenSize()}px`
    // marginLeft: theme.spacing.unit * 2,
    // marginRight: theme.spacing.unit * 2,
    // [theme.breakpoints.up(600 + theme.spacing.unit * 2 * 2)]: {
    //   marginLeft: "auto",
    //   marginRight: "auto"
    // }
  },
  paper: {
    height: "scroll",
    boxShadow: "none",
    flexGrow: 1,
    // marginTop: theme.spacing.unit * 3,
    // marginBottom: theme.spacing.unit * 3,
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
    height: `${getScreenSize()}px`,
    overflowY: "scroll"
    // [theme.breakpoints.up(900 + theme.spacing.unit * 3 * 2)]: {
    //   // {      marginTop: theme.spacing.unit * 6,
    //   //       marginBottom: theme.spacing.unit * 6,}
    //   padding: theme.spacing.unit * 3
    // }
  },
  left_container: {
    backgroundColor: "#f70000;"
  },
  stepper: {
    padding: `${theme.spacing.unit * 3}px 0 ${theme.spacing.unit * 5}px`,
    iconColor: "green" // or logic to change color
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end"
  },
  button: {
    marginTop: theme.spacing.unit * 3,
    marginLeft: theme.spacing.unit
  }
});

const initialSize = {
  innerHeight: window.innerHeight,
  innerWidth: window.innerWidth
};

const App = props => {
  const { classes, t } = props;

  const [size, handleResize] = useState(initialSize);

  useEffect(() => {
    window.addEventListener("resize", () => {
      const innerHeight = window.innerHeight;
      const innerWidth = window.innerWidth;
      handleResize({ innerHeight, innerWidth });
    });

    //runGoogleTag();
  }, []);

  return (
    <div data-test style={{ width: size.innerWidth, height: size.innerHeight }}>
      <CssBaseline />
      <main className={classes.layout}>
        <Grid container direction="row" justify="center" alignContent="center">
          <Grid
            container
            xs={12}
            md={3}
            lg={3}
            className={classes.left_container}
            justify="center"
            alignContent="center"
            alignItems="center"
          >
            <Grid item xs={3} md={12}>
              <Logo style={{ width: "100%", padding: 20 }} />
            </Grid>

            <Grid style={{ color: "#fff200" }} item xs={9} md={12}>
              <h3 style={{ textAlign: "center" }}>
                Tilaus onnistuu helposti valitsemalla ongelman syy.
                <br />
                Meille voit myös soittaa
                <br />
                <a
                  style={{
                    with: "100%",
                    textAlign: "center",
                    color: "#fff200",
                    textDecoration: "none"
                  }}
                  href="tel:09 350 8550"
                >
                  09 350 8550
                </a>
              </h3>
            </Grid>
          </Grid>
          <Grid xs={12} md={9} lg={9}>
            <Paper className={classes.paper}>
              <React.Fragment>
                <Grid
                  container
                  spacing={24}
                  justify="center"
                  style={{ padding: "0 30px" }}
                >
                  <Context>
                    <StatusModal />
                    <PhaseStepper className={classes.stepper} />
                    <GeneratePages t={t} />
                  </Context>
                </Grid>
              </React.Fragment>
            </Paper>
          </Grid>
        </Grid>
      </main>
    </div>
  );
};

App.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(App);
