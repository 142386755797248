import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import RadioSelection from "./components/RadioSelection";
import DateTimePicker from "./components/DateTimePicker";
import { CXContext } from "./context/Context";
import PhaseButtons from "./PhaseButtons";
import { DELIVERY_OPTIONS } from "./constants";
import { getSubtasks, pageQuestionObject, checkIsDisabled } from "./utils";
import moment from "moment";

import { formLabel } from "./styles";

const styles = theme => ({
  root: {
    display: "flex"
  },
  formControl: {
    margin: theme.spacing.unit * 3
  },
  formLabel: formLabel
});

const DATE_TIME = "Nouto aika";

const Aikataulu = ({ classes, pageQuestion }) => {
  const [state, setState] = useState({});

  const context = useContext(CXContext);
  const { mainJson } = context;

  const isDisabled = checkIsDisabled([pageQuestion], state);

  const handleDataUpdate = (label, values) => {
    if (label === DATE_TIME) {
      values = moment(values).format("DD.MM.YYYY HH:mm");
    }
    const nextState = {
      ...state,
      [label]: values
    };
    setState(nextState);
  };

  useEffect(() => {
    if (mainJson[pageQuestion]) {
      const prevState = mainJson[pageQuestion];
      setState(prevState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <FormControl component="fieldset" className={classes.formControl}>
          <FormLabel classes={{ root: classes.formLabel }} component="legend">
            {pageQuestion}
          </FormLabel>
          <RadioSelection
            currentValue={state[pageQuestion]}
            onSelect={value => {
              handleDataUpdate(pageQuestion, value);
            }}
            options={DELIVERY_OPTIONS}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        {state[pageQuestion] === "Ennakkotilaus" && (
          <DateTimePicker
            initiaDate={state[DATE_TIME]}
            onChange={value => {
              handleDataUpdate(DATE_TIME, value);
            }}
          />
        )}
      </Grid>

      <PhaseButtons
        isDisabled={isDisabled}
        formValues={{
          [pageQuestion]: {
            ...state
          }
        }}
      />
    </Grid>
  );
};

Aikataulu.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Aikataulu);
