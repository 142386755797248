import React, { useState, useEffect } from "react";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import FormLabel from "@material-ui/core/FormLabel";
import DropDownSelect from "../components/DropDownSelect";
import SingleTextField from "../components/SingleTextField";
import DateTimePicker from "../components/DateTimePicker";
import GoogleAutoComplete from "../components/GoogleAutoComplete";
import Comment from "../components/Comment";
import Map from "../components/Map";
import moment from "moment";
import { prop, sortBy, compose, toLower } from "ramda";
import {
  WORK_INFORMATION,
  orderDateLabelValue,
  orderTimeLabelValue,
  towingReasonLabelValue,
  towingReasonList,
  orderReferenceLabelValue,
  notesLabelValue,
  additionalInformationLabelValue,
  descriptionFromLabelValue,
  descriptionToLabelValue,
  COORDINATES,
  ADDRESS,
  PARTNER,
  FROM,
  fromLabelValue,
  toLabelValue,
} from "../constants";

import {
  fetchClosestPartner,
  getPartners,
  generatePartnerOptionsLabelValue,
} from "../utils";

const SectionWorkInformation = ({ classes, title, onChange, t }) => {
  const [state, setState] = useState({ partnerList: [] });

  useEffect(() => {
    const { partnerList, ...rest } = state;
    onChange(WORK_INFORMATION, rest);
  }, [state]);

  useEffect(() => {
    const run = async () => {
      const partners = await getPartners();

      const partnerList = prop("data", partners) ? prop("data", partners) : [];
      const sortByNameCaseInsensitive = sortBy(compose(toLower, prop("name")));
      const sortedPartnerList = sortByNameCaseInsensitive(partnerList);

      setState({ ...state, partnerList: sortedPartnerList });
    };

    run();
  }, []);

  const handleChange = (label, value) => {
    let nextState;
    if (label === orderDateLabelValue.value) {
      const date = moment(value).format("YYYY-MM-DD");
      const time = moment(value).format("hh:mm");

      nextState = {
        ...state,
        [orderDateLabelValue.value]: date,
        [orderTimeLabelValue.value]: time,
      };
    } else {
      nextState = { ...state, [label]: value };
    }
    setState((prevState) => {
      return { ...prevState, [label]: value };
    });
  };

  const handleAddress = async (label, geoLatLng, streetAddress) => {
    handleChange(label, {
      [COORDINATES]: { ...geoLatLng },
      [ADDRESS]: streetAddress,
    });
    if (label === fromLabelValue.value) {
      const partner = await handleClosestPartner(geoLatLng);
      handleChange(PARTNER, partner);
    }
  };

  const handleClosestPartner = async (coordinates) => {
    const { partner } = await fetchClosestPartner(coordinates);
    return partner;
  };

  const handleSelectPartner = (label, value) => {
    const partner = state.partnerList.find((partner) => partner._id === value);
    if (!partner) {
      return;
    }
    setState({ ...state, [PARTNER]: { ...partner } });
  };

  const partnerOptions = generatePartnerOptionsLabelValue(state.partnerList);
  console.log({ state });
  const closestPartnerMarker = !!state[PARTNER] && {
    lat: state[PARTNER].coordinates.x,
    lng: state[PARTNER].coordinates.y,
  };

  const userMarker = !!state[FROM] && {
    lat: state[FROM].coordinates.lat,
    lng: state[FROM].coordinates.lng,
  };

  return (
    <div>
      <FormLabel classes={{ root: classes.formLabel }} component="legend">
        {title}
      </FormLabel>
      <Grid container direction="row">
        <div>
          <FormControl className={classes.formControl}>
            <DropDownSelect
              label={towingReasonLabelValue.label}
              id={towingReasonLabelValue.value}
              options={towingReasonList}
              onChange={handleChange}
            />
          </FormControl>

          <FormControl className={classes.formControl}>
            <SingleTextField
              required={false}
              onChange={handleChange}
              label={orderReferenceLabelValue.label}
              id={orderReferenceLabelValue.value}
            />
          </FormControl>
          <Grid item xs={12}>
            <FormControl className={classes.formControl}>
              <Comment
                showIconAndLabel={false}
                className={classes.comment}
                label={additionalInformationLabelValue.label}
                id={additionalInformationLabelValue.value}
                onChange={handleChange}
                title="Lisää muistiinpano"
                active={true}
              />
            </FormControl>
          </Grid>
        </div>
        <Grid container direction="row">
          <Grid item>
            <DateTimePicker
              id={orderDateLabelValue.value}
              label={orderDateLabelValue.label}
              onChange={handleChange}
            />
          </Grid>
          <Grid container>
            <Grid item direction="column" xs={5.5}>
              <GoogleAutoComplete
                placeholder={fromLabelValue.label}
                onAddressSelect={(geo, street) => {
                  handleAddress(fromLabelValue.value, geo, street);
                }}
                styles={{
                  input: {
                    border: "1px solid #CCC",
                  },
                }}
                loadingElement={<div style={{ height: `100%` }} />}
                googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBZa_nTG1bxGmQCf3s_qVNMFIKPvz0oLkw&v=3.exp&libraries=geometry,drawing,places"
              />

              <FormControl className={classes.formControl}>
                <Comment
                  showIconAndLabel={false}
                  className={classes.comment}
                  label={descriptionFromLabelValue.label}
                  id={descriptionFromLabelValue.value}
                  onChange={handleChange}
                  styles={{ input: { margin: "10px 10px 10px 0" } }}
                  title="Lisätietoa noutoon"
                  active={true}
                />
              </FormControl>
            </Grid>
            <Grid item xs={1} />
            <Grid item direction="column" xs={5.5}>
              <GoogleAutoComplete
                placeholder={toLabelValue.label}
                onAddressSelect={(geo, street) => {
                  handleAddress(toLabelValue.value, geo, street);
                }}
                styles={{
                  input: {
                    border: "1px solid #CCC",
                  },
                }}
                loadingElement={<div style={{ height: `100%` }} />}
                googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBZa_nTG1bxGmQCf3s_qVNMFIKPvz0oLkw&v=3.exp&libraries=geometry,drawing,places"
              />
              <FormControl className={classes.formControl}>
                <Comment
                  showIconAndLabel={false}
                  className={classes.comment}
                  styles={{ input: { margin: "10px 10px 10px 0" } }}
                  label={descriptionToLabelValue.label}
                  id={descriptionToLabelValue.value}
                  onChange={handleChange}
                  title="Lisätietoa toimitukseen"
                  active={true}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid item xs={12} direction="row">
            <FormControl className={classes.formControl}>
              <DropDownSelect
                label={t("labelPartner")}
                id={"labelPartner"}
                value={!!state[PARTNER] && state[PARTNER]["_id"]}
                options={partnerOptions}
                onChange={handleSelectPartner}
              />
            </FormControl>
            {state.partnerList.length > 0 && (
              <Map
                onSelectPartner={handleSelectPartner}
                partners={state.partnerList}
                userMarker={userMarker}
                center={closestPartnerMarker}
                zoom={4}
                isMarkerShown={true} //{!!state[PARTNER]}
                googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBZa_nTG1bxGmQCf3s_qVNMFIKPvz0oLkw&v=3.exp&libraries=geometry,drawing,places"
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `400px` }} />}
                mapElement={<div style={{ height: `100%` }} />}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default SectionWorkInformation;
