import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const styles = theme => ({
  button: {
    marginTop: theme.spacing.unit * 3,
    marginLeft: theme.spacing.unit,
    boxShadow: "none"
  },
  input: {
    display: "none"
  }
});

const Buttoni = props => {
  const { classes, variant, color, disabled, children, onChange } = props;

  return (
    <Button
      onClick={onChange}
      variant={variant}
      color={color}
      disabled={disabled}
      className={classes.button}
    >
      {children}
    </Button>
  );
};

Buttoni.propTypes = {
  onChange: PropTypes.func,
  children: PropTypes.element,
  classes: PropTypes.object.isRequired,
  variant: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool
};

Buttoni.defaultProps = {
  variant: "contained",
  color: "primary",
  disabled: false
};

export default withStyles(styles)(Buttoni);
