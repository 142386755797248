import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";

const styles = theme => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit
  }
});

const MultiTextField = props => {
  const { classes, label, helperText, onChange, currentValue } = props;
  const [value, handleValue] = useState("");

  useEffect(() => {
    handleValue(currentValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentValue]);

  const handleTextInput = e => {
    const value = e.target.value;
    onChange(label, value);
    handleValue(value);
  };

  return (
    <TextField
      id="outlined-multiline-static"
      label={label}
      multiline
      rows="4"
      value={value}
      className={classes.textField}
      margin="normal"
      variant="outlined"
      onChange={handleTextInput}
      helperText={helperText}
    />
  );
};

MultiTextField.propTypes = {
  classes: PropTypes.object.isRequired
};

MultiTextField.defaultProps = {
  currentValue: ""
};

export default withStyles(styles)(MultiTextField);
