import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";

const styles = theme => ({
  root: {
    overflowY: "scroll",
    margin: "0 auto",
    width: "65%"
  },
  contentContainer: {
    backgroundColor: "white"
  },
  buttonGroupContainer: {
    width: "100%",
    height: 70,
    backgroundColor: "#CCC"
  },
  button: {
    marginTop: theme.spacing.unit * 3,
    marginLeft: theme.spacing.unit,
    boxShadow: "none"
  }
});

const AdminModal = props => {
  const {
    content,
    isOpen,
    onClose,
    onAction,
    classes,
    t,
    hasActionButton,
    hasCancelButton,
    cancelHeader,
    actionHeader
  } = props;

  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  const handleAction = () => {
    onAction();
  };

  return (
    <Modal
      disableAutoFocus
      className={classes.root}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={open}
      onClose={handleClose}
    >
      <div>
        <div classsName={classes.contentContainer}>{content}</div>
        <div
          style={{
            width: "100%",
            height: 70,
            backgroundColor: "white",
            paddingTop: 5
          }}
        >
          {hasActionButton && (
            <Button
              id="phase-button-submit"
              color="primary"
              variant="contained"
              onClick={() => {
                handleAction();
              }}
              className={classes.button}
            >
              {t(actionHeader)}
            </Button>
          )}
          {hasCancelButton && (
            <Button
              id="phase-button-cancel"
              color="default"
              variant="contained"
              onClick={() => {
                handleClose();
              }}
              className={classes.button}
            >
              {t(cancelHeader)}
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
};

AdminModal.propTypes = {
  t: PropTypes.func
};
AdminModal.defaultProps = {
  t: e => e,
  isOpen: false,
  onClose: e => e,
  hasActionButton: false,
  hasCancelButton: true,
  hasActionButton: false,
  actionHeader: "buttonSubmit",
  cancelHeader: "buttonGoBack"
};

export default withStyles(styles)(AdminModal);
