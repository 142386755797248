import React, { useReducer, useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  GAPageViewAction,
  GACreateNewOrderAction,
  getPartnersByCompanyId
} from "../utils";
export const CXContext = React.createContext("CXContext");

const GO_BACK = "goBack";
const GO_FORWARD = "goForward";
const FORM_SUCCESS = "formSuccess";
const FORM_ERROR = "formError";
const PROCESS_COMPLETE = "processComplete";
const STEPS = 9;

const reducer = (state, action) => {
  switch (action.type) {
    case GO_FORWARD:
      GAPageViewAction(state.activeStep + 1);
      return {
        ...state,
        activeStep: state.activeStep + 1
      };
    case GO_BACK:
      GAPageViewAction(state.activeStep - 1);
      return {
        ...state,
        activeStep: state.activeStep - 1
      };
    case FORM_SUCCESS:
      GACreateNewOrderAction();
      return {
        ...state,
        formSubmitted: true,
        formError: false,
        activeStep: 0
      };
    case FORM_ERROR:
      return {
        ...state,
        formSubmitted: true,
        formError: true
      };
    case PROCESS_COMPLETE:
      return {
        ...state,
        formSubmitted: false,
        formError: false
      };
    default:
      return;
  }
};

const initialState = {
  activeStep: 0,
  formSubmitted: false,
  formError: false,
  companyConfig: {
    companyId: 2040,
    styles: {
      backgroundColor: "",
      color: "",
      logo: "",
      fontFamily: ""
    }
  }
};

const Context = props => {
  const [path, handlePath] = useState(null);
  const [mainJson, handleMergeToJson] = useState({});
  const [state, dispatch] = useReducer(reducer, initialState);

  const onMergeToJson = (keyValue, clearOld = false) => {
    if (clearOld) {
      handleMergeToJson(keyValue);
    } else {
      handleMergeToJson({ ...mainJson, ...keyValue });
    }
  };

  const handleProcessComplete = () => {
    handleMergeToJson({});
    dispatch({ type: PROCESS_COMPLETE });
  };
  const { children } = props;

  return (
    <CXContext.Provider
      value={{
        stepForward: () => {
          dispatch({ type: GO_FORWARD });
        },
        stepBack: () => {
          dispatch({ type: GO_BACK });
        },
        FormSuccess: data => {
          dispatch({ type: FORM_SUCCESS, payload: data });
        },
        FormError: error => {
          dispatch({ type: FORM_ERROR, payload: error });
        },
        countOfSteps: [...Array(STEPS).keys()].map(() => " "),
        step: state.activeStep,
        handlePath,
        handleProcessComplete,
        path,
        mainJson,
        onMergeToJson,
        formSubmitted: state.formSubmitted,
        formError: state.formError
      }}
    >
      {children}
    </CXContext.Provider>
  );
};

Context.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default Context;
