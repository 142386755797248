import React, { useReducer, useEffect } from "react";
import PropTypes from "prop-types";
import * as R from "ramda";
import { withStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import AdminOrderMenu from "./AdminOrderMenu";
import RefreshIcon from "@material-ui/icons/Refresh";
import AdminOrderListFilters from "./AdminOrderListFilters";
import AdminModalContentOrderDetails from "./AdminModalContentOrderDetails";
import AdminModal from "./AdminModal";

import IconButton from "@material-ui/core/IconButton";
import { mapCompanyIdsToNames, fetchOrders, getPartners } from "../utils";

const FIELD_INNOVOICE_COMPANY_NAME = "name";
const FIELD_INNOVOICE_COMPANY_ID = "innovoice_company_id";
const FIELD_COMPANY_ID = "company_id";
const FIELD_COMPANY_NAME = "company_name";
const FIELD_ID = "id";
const FIELD_ORDER_DATE = "order_date";
const FIELD_FROM_ADDRESS = "from_address";
const FIELD_STATUS = "status";
const FIELD_VEHICLE_REG_NO = "vehicle_reg_no";
const FIELD_REFRESH = "refresh";

const filterList = [
  FIELD_COMPANY_NAME,
  FIELD_ID,
  FIELD_ORDER_DATE,
  FIELD_FROM_ADDRESS,
  FIELD_STATUS,
  FIELD_VEHICLE_REG_NO,
];

const handleFilterSelectConfig = (filterList, t, data) => {
  return R.map((filterLabel) => {
    let options = [];
    R.forEach((order) => {
      if (
        order[filterLabel] &&
        options.findIndex((option) => {
          return option.value === order[filterLabel];
        }) === -1
      ) {
        options.push({
          label: t(order[filterLabel]),
          value: order[filterLabel],
        });
      }
    }, data);
    return { label: filterLabel, value: "", options };
  }, filterList);
};

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "white",
    color: "black",
  },
  body: {
    fontSize: 12,
  },
}))(TableCell);

const styles = (theme) => ({});

const initialState = {
  filterConfig: [],
  activeFilters: {},
  orderList: [],
  modalOpen: false,
};

const UPDATE_ORDER_LIST = "update_order_list";
const OPEN_MODAL_WITH_DETAILS = "openModalWithDetails";
const CLOSE_MODAL = "closeModal";
const ADD_FILTER_SELECTION_CONFIG = "addFilterSelectionConfig";
const SET_ACTIVE_FILTERS = "setActiveFilters";

const reducer = (state, action) => {
  switch (action.type) {
    case SET_ACTIVE_FILTERS:
      return {
        ...state,
        activeFilters: { ...state.activeFilters, ...action.payload },
      };
    case ADD_FILTER_SELECTION_CONFIG:
      return { ...state, filterConfig: action.payload };
    case UPDATE_ORDER_LIST:
      return { ...state, orderList: action.payload };
    case OPEN_MODAL_WITH_DETAILS:
      return {
        ...state,
        modalOpen: true,
        recordChosenForDetails: action.payload,
      };
    case CLOSE_MODAL:
      return { ...state, modalOpen: false, recordChosenForDetails: null };
    default:
      throw new Error("No action type found");
  }
};

const AdminOrderList = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { classes, t } = props;
  useEffect(() => {
    const runOrderGet = async () => {
      const orders = await fetchOrders();
      const partners = await getPartners();

      const partnerObjec = partners.data.reduce((acc, curr) => {
        acc = {
          ...acc,
          [curr[FIELD_INNOVOICE_COMPANY_ID]]:
            curr[FIELD_INNOVOICE_COMPANY_NAME],
        };
        return acc;
      }, {});
      const ordersWithPartner = orders.data.map((order) => {
        return {
          ...order,
          company_name: partnerObjec[order[FIELD_COMPANY_ID]],
        };
      });

      const filterConfig = handleFilterSelectConfig(
        filterList,
        t,
        ordersWithPartner
      );
      dispatch({ type: UPDATE_ORDER_LIST, payload: ordersWithPartner || [] });
      dispatch({
        type: ADD_FILTER_SELECTION_CONFIG,
        payload: filterConfig || [],
      });
    };

    runOrderGet();
  }, []);

  const handleRowDetails = (row) => {
    dispatch({ type: OPEN_MODAL_WITH_DETAILS, payload: row });
  };

  const handleCloseModalAndClearRowDetails = () => {
    dispatch({ type: CLOSE_MODAL });
  };

  const handleRowRefresh = (row) => {
    const runOrderGet = async () => {
      const order = await fetchOrders(row);
      const data = order.data || [];
      const index = R.findIndex(R.propEq("_id", data._id))(state.orderList); //=> 1
      const filteredOrderList = R.filter(
        (record) => record._id !== data._id,
        state.orderList
      );
      const updatedOrderList = R.insert(index, data, filteredOrderList);

      dispatch({ type: UPDATE_ORDER_LIST, payload: updatedOrderList });
    };

    runOrderGet();
  };

  const handleFilter = (label, value) => {
    if (value == null) {
      value = undefined;
    }
    dispatch({
      type: SET_ACTIVE_FILTERS,
      payload: { [label]: value },
    });
  };

  const CellUpdateRow = ({ cell }) => {
    return (
      <StyledTableCell>
        <IconButton
          onClick={() => handleRowRefresh(cell)}
          color="primary"
          component="span"
        >
          <RefreshIcon />
        </IconButton>
      </StyledTableCell>
    );
  };

  const CellDetailsRow = ({ cell }) => {
    return (
      <StyledTableCell
        style={{ cursor: "pointer", color: "red", fontStyle: "bold" }}
        onClick={() => handleRowDetails(cell)}
      >
        {cell["id"]}
      </StyledTableCell>
    );
  };

  const filteredList = R.filter((order) => {
    let validate = true;
    if (!!R.isEmpty(state.activeFilters)) {
      return validate;
    }

    R.forEach((filter) => {
      if (!state.activeFilters[filter]) {
        return;
      }
      if (
        !state.activeFilters[filter].find(
          (option) => option.value === order[filter]
        )
      ) {
        validate = false;
      }
    }, R.keys(state.activeFilters));
    return validate;
  }, state.orderList);

  const sortedList = R.sortBy(R.prop(FIELD_ORDER_DATE), filteredList);

  console.log({ YYY: state });
  return (
    <div data-test className={classes.root}>
      <AdminModal
        t={t}
        isOpen={state.modalOpen}
        onClose={handleCloseModalAndClearRowDetails}
        content={
          <AdminModalContentOrderDetails
            t={t}
            record={state.recordChosenForDetails}
          />
        }
      />
      <CssBaseline>
        <Grid justify="center" alignItems="center" container direction="column">
          <Grid xs={10} container style={{ background: "white" }}>
            <AdminOrderMenu t={t} />
            <AdminOrderListFilters
              t={t}
              filterValues={state.activeFilters}
              onChange={handleFilter}
              config={{
                filters: state.filterConfig,
              }}
            />
            <Table className={classes.table} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>{t([FIELD_COMPANY_NAME])}</StyledTableCell>
                  <StyledTableCell>{t(FIELD_ID)}</StyledTableCell>
                  <StyledTableCell>{t(FIELD_ORDER_DATE)}</StyledTableCell>
                  <StyledTableCell>{t(FIELD_FROM_ADDRESS)}</StyledTableCell>
                  <StyledTableCell>{t(FIELD_VEHICLE_REG_NO)}</StyledTableCell>
                  <StyledTableCell>{t(FIELD_STATUS)}</StyledTableCell>
                  <StyledTableCell>{t(FIELD_REFRESH)}</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {R.map(
                  (row, key) => (
                    <TableRow key={FIELD_ID}>
                      <StyledTableCell>
                        {mapCompanyIdsToNames(row[FIELD_COMPANY_NAME])}
                      </StyledTableCell>
                      <CellDetailsRow cell={row} />
                      <StyledTableCell>
                        {t(row[FIELD_ORDER_DATE])}
                      </StyledTableCell>
                      <StyledTableCell>
                        {t(row[FIELD_FROM_ADDRESS])}
                      </StyledTableCell>
                      <StyledTableCell>
                        {t(row[FIELD_VEHICLE_REG_NO])}
                      </StyledTableCell>
                      <StyledTableCell>{t(row[FIELD_STATUS])} </StyledTableCell>
                      <CellUpdateRow cell={row} />
                    </TableRow>
                  ),
                  sortedList
                )}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </CssBaseline>
    </div>
  );
};

AdminOrderList.prototypes = {
  classes: PropTypes.object,
  t: PropTypes.func.isRequired,
};

export default withStyles(styles)(AdminOrderList);
