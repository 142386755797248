import React, { useReducer, useEffect } from "react";
import PropTypes from "prop-types";
import * as R from "ramda";
import { withStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import AdminOrderMenu from "./AdminOrderMenu";
import AdminModalContentPartnerDetails from "./AdminModalContentPartnerDetails";
import AdminModal from "./AdminModal";

import EditIcon from "@material-ui/icons/Edit";
import TrashIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import {
  mapCompanyIdsToNames,
  getPartners,
  partnerUpdate,
  deletePartnerById,
  createNewPartner,
} from "../utils";
import {
  FIELD_COMPANY_NAME,
  FIELD_COMPANY_ID,
  FIELD_BRANCH,
  FIELD_BRANCH_ID,
  FIELD_FROM_ADDRESS,
  FIELD_COORDINATES,
  FIELD_COORDINATES_X,
  FIELD_COORDINATES_Y,
  FIELD_PHONE,
  FIELD_EMAIL,
  FIELD_NOTIFICATION_SMS,
  FIELD_NOTIFICATION_EMAIL,
  FIELD_RANGE,
  FIELD_MODIFY_ROW,
  FIELD_DELETE_ROW,
} from "../constants";

const listOfTableFields = [
  FIELD_COMPANY_NAME,
  FIELD_PHONE,
  FIELD_EMAIL,
  FIELD_BRANCH,
  FIELD_FROM_ADDRESS,
];

const listOfFormFields = [
  FIELD_COMPANY_NAME,
  FIELD_PHONE,
  FIELD_EMAIL,
  FIELD_NOTIFICATION_SMS,
  FIELD_NOTIFICATION_EMAIL,
  FIELD_COMPANY_ID,
  FIELD_BRANCH,
  FIELD_BRANCH_ID,
  FIELD_FROM_ADDRESS,
  FIELD_COORDINATES_X,
  FIELD_COORDINATES_Y,
  FIELD_RANGE,
];

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: "white",
    color: "black",
  },
  body: {
    fontSize: 12,
  },
}))(TableCell);

const styles = () => ({
  tableContainer: {
    minWidth: 650,
    overflowX: "scroll",
  },
});

const initialState = {
  recordChosenForDetails: null,
  partnerList: [],
  modalOpen: false,
  rowHovered: null,
};

const UPDATE_PARTNER_LIST = "update_partner_list";
const OPEN_MODAL_WITH_DETAILS = "openModalWithDetails";
const OPEN_MODAL_WITH_NEW_RECORD = "openModalWithNewRecord";
const DELETE_CURRENT_RECORD = "deleteCurrentRecord";
const CHANGE_CURRENT_RECORD = "changeCurrentRecord";
const CREATE_NEW_RECORD = "createNewRecord";
const SUBMIT_CURRENT_RECORD = "submitCurrentRecord";
const CLOSE_MODAL = "closeModal";
const UPDATE_IS_HOVERED = "update_is_hovered";

const reducer = (state, action) => {
  switch (action.type) {
    case CHANGE_CURRENT_RECORD:
      return {
        ...state,
        recordChosenForDetails: {
          ...state.recordChosenForDetails,
          ...action.payload,
        },
      };
    case CREATE_NEW_RECORD:
      return {
        ...state,
        modalOpen: false,
        recordChosenForDetails: null,
        partnerList: action.payload,
      };
    case DELETE_CURRENT_RECORD:
      return {
        ...state,
        recordChosenForDetails: null,
        rowHovered: null,
        partnerList: action.payload,
      };
    case SUBMIT_CURRENT_RECORD:
      return {
        ...state,
        recordChosenForDetails: null,
        modalOpen: false,
        rowHovered: null,
        partnerList: action.payload,
      };
    case UPDATE_IS_HOVERED:
      return { ...state, rowHovered: action.payload };
    case UPDATE_PARTNER_LIST:
      return { ...state, partnerList: action.payload };
    case OPEN_MODAL_WITH_DETAILS:
      return {
        ...state,
        modalOpen: true,
        modalContent: "edit",
        recordChosenForDetails: action.payload,
      };
    case OPEN_MODAL_WITH_NEW_RECORD:
      return {
        ...state,
        modalOpen: true,
        modalContent: "create",
      };
    case CLOSE_MODAL:
      return { ...state, modalOpen: false, recordChosenForDetails: null };
    default:
      throw new Error("No action type found");
  }
};

const AdminPartnerList = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { classes, t } = props;
  useEffect(() => {
    const runPartnerGet = async () => {
      const partners = await getPartners();
      dispatch({ type: UPDATE_PARTNER_LIST, payload: partners.data || [] });
    };

    runPartnerGet();
  }, []);

  const handleEditPartner = (row) => {
    dispatch({ type: OPEN_MODAL_WITH_DETAILS, payload: row });
  };

  const handleChangePartnerValues = ({ label, value }) => {
    if (label === "x" || label === "y") {
      const coordinates = {
        coordinates: {
          ...R.path(["recordChosenForDetails", "coordinates"], state),
          [label]: value,
        },
      };

      dispatch({ type: CHANGE_CURRENT_RECORD, payload: coordinates });
    } else {
      dispatch({ type: CHANGE_CURRENT_RECORD, payload: { [label]: value } });
    }
  };

  const handleCreatePartner = (row) => {
    dispatch({ type: OPEN_MODAL_WITH_NEW_RECORD });
  };

  const handleRecordDelete = async (row) => {
    const partnerId = R.prop("_id", row);
    if (!partnerId) {
      return;
    }
    const response = await deletePartnerById(partnerId);
    if (response.status === 200) {
      const filterDeletedPartner = R.filter(
        (partner) => R.prop("_id", partner) !== partnerId,
        state.partnerList
      );
      dispatch({ type: DELETE_CURRENT_RECORD, payload: filterDeletedPartner });
    }
  };

  const handleAction = async () => {
    if (state.modalContent === "edit") {
      const updatedPartner = await partnerUpdate(state.recordChosenForDetails);

      const listNotContainingChangedRecord = R.filter(
        (partner) => R.prop("_id", partner) !== R.prop("_id", updatedPartner),
        state.partnerList
      );

      const listWithChangedRecord = [
        updatedPartner,
        ...listNotContainingChangedRecord,
      ];
      dispatch({ type: SUBMIT_CURRENT_RECORD, payload: listWithChangedRecord });
    } else if (state.modalContent === "create") {
      const newPartner = await createNewPartner(state.recordChosenForDetails);
      const listWithNewPartner = [newPartner, ...state.partnerList];

      dispatch({ type: CREATE_NEW_RECORD, payload: listWithNewPartner });
    }
  };

  const handleCloseModalAndClearRowDetails = () => {
    dispatch({ type: CLOSE_MODAL });
  };

  const handleButtonHover = (index) => {
    dispatch({ type: UPDATE_IS_HOVERED, payload: index });
  };

  const CellEditRow = ({ cell }) => {
    return (
      <StyledTableCell>
        <IconButton
          onClick={() => handleEditPartner(cell)}
          color="primary"
          component="span"
        >
          <EditIcon />
        </IconButton>
      </StyledTableCell>
    );
  };

  const CellDeleteRow = ({ cell }) => {
    return (
      <StyledTableCell>
        <IconButton
          onClick={() => handleRecordDelete(cell)}
          color="red"
          component="span"
        >
          <TrashIcon />
        </IconButton>
      </StyledTableCell>
    );
  };

  return (
    <div data-test className={classes.root}>
      <AdminModal
        hasActionButton={true}
        t={t}
        onAction={handleAction}
        isOpen={state.modalOpen}
        onClose={handleCloseModalAndClearRowDetails}
        content={
          <AdminModalContentPartnerDetails
            onChange={handleChangePartnerValues}
            listOfFields={listOfFormFields}
            t={t}
            record={state.recordChosenForDetails}
          />
        }
      />
      <CssBaseline>
        <Grid justify="center" alignItems="center" container direction="column">
          <Grid xs={11} container style={{ background: "white" }}>
            <AdminOrderMenu t={t} />
            <Grid
              style={{ display: "flex" }}
              item
              xs={12}
              direction="row"
              justify="flex-end"
              alignItems="center"
            >
              <Button
                onClick={handleCreatePartner}
                style={{ margin: 5 }}
                variant="contained"
                color="primary"
              >
                {t("buttonCreateNewPartner")}
              </Button>
            </Grid>
            <Grid item xs={12} className={classes.tableContainer}>
              <Table aria-label="customized table" size="small">
                <TableHead>
                  <TableRow>
                    {listOfTableFields.map((header) => (
                      <StyledTableCell>{t(header)}</StyledTableCell>
                    ))}
                    <StyledTableCell>{t(FIELD_MODIFY_ROW)}</StyledTableCell>
                    <StyledTableCell>{t(FIELD_DELETE_ROW)}</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {state.partnerList.map((row, index) => (
                    <TableRow
                      hover={true}
                      key={index}
                      onMouseEnter={() => {
                        handleButtonHover(index);
                      }}
                      onMouseLeave={() => {
                        handleButtonHover(null);
                      }}
                    >
                      {listOfTableFields.map((field) => {
                        if (field === FIELD_COORDINATES_X)
                          return (
                            <StyledTableCell>
                              {t(R.pathOr("", [FIELD_COORDINATES, "x"], row))}
                            </StyledTableCell>
                          );
                        if (field === FIELD_COORDINATES_Y)
                          return (
                            <StyledTableCell>
                              {t(R.pathOr("", [FIELD_COORDINATES, "y"], row))}
                            </StyledTableCell>
                          );
                        else {
                          return (
                            <StyledTableCell>
                              {t(R.prop(field, row))}
                            </StyledTableCell>
                          );
                        }
                      })}

                      {state.rowHovered == index ? (
                        <>
                          <CellEditRow cell={row} />
                          <CellDeleteRow cell={row} />
                        </>
                      ) : (
                        <>
                          <StyledTableCell></StyledTableCell>
                          <StyledTableCell></StyledTableCell>
                        </>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </Grid>
      </CssBaseline>
    </div>
  );
};

AdminPartnerList.prototypes = {
  classes: PropTypes.object,
  t: PropTypes.func.isRequired,
};

export default withStyles(styles)(AdminPartnerList);
