import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

const styles = {
  root: {
    backgroundColor: "white"
  }
};

const AdminModalContentOrderDetails = ({ record, classes, t }) => {
  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: "30%" }}>
                {t("tableHeaderKey")}
              </TableCell>
              <TableCell>{t("tableHeaderValue")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(record).map((row, key) => (
              <TableRow>
                <TableCell>{t(row)}</TableCell>
                <TableCell>{t(record[row])}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
};

AdminModalContentOrderDetails.prototypes = {
  record: PropTypes.object,
  classes: PropTypes.object,
  t: PropTypes.func.isRequired
};

AdminModalContentOrderDetails.defaultProps = {
  record: {}
};

export default withStyles(styles)(AdminModalContentOrderDetails);
