import React from "react";
import PropTypes from "prop-types";
import FormLabel from "@material-ui/core/FormLabel";
import { withStyles } from "@material-ui/core/styles";
import Autocomplete from "@scorchiowned/react-google-autocomplete-fork";
import { withScriptjs } from "react-google-maps";
import * as R from "ramda";

import { formLabel } from "../styles";

const defaultStyles = () => ({
  root: {
    display: "flex",
  },
  formControl: {
    margin: 30,
  },
  formLabel: formLabel,
  input: {
    backgroundColor: "inherit",
    width: "100%",
    height: "40px",
    paddingLeft: "16px",
    marginTop: "2px",
  },
});

const GoogleAutoComplete = withScriptjs((props) => {
  const { onAddressSelect, label, placeholder, classes, styles } = props;

  const onPlaceSelected = (place) => {
    const lat = R.path(["geometry", "location", "lat"], place);
    const lng = R.path(["geometry", "location", "lng"], place);

    if (!lat | !lng) {
      console.log("No latitude or longitude provided");
      return;
    }

    const address_components = R.prop("address_components", place);
    const streetAddress = R.prop("formatted_address", place);

    const geoLatLng = { lat: lat(), lng: lng() };

    onAddressSelect(geoLatLng, streetAddress);
  };

  return (
    <>
      <FormLabel classes={{ root: classes.formLabel }} component="legend">
        {label}
      </FormLabel>
      <Autocomplete
        data-test="input-autocomplete"
        placeholder={placeholder}
        style={{ ...defaultStyles().input, ...styles.input }}
        onPlaceSelected={onPlaceSelected}
        types={"address"}
        componentRestrictions={{ country: "fi" }}
      />
    </>
  );
});

GoogleAutoComplete.propTypes = {
  onAddressSelect: PropTypes.func,
  styles: PropTypes.object,
};

GoogleAutoComplete.defaultProps = {
  onAddressSelect: (e) => e,
  styles: {},
};

export default withStyles(defaultStyles)(GoogleAutoComplete);
