import React from "react";
import { Route } from "react-router-dom";

const ClientRoute = ({ component: Component, t, ...rest }) => {
  const renderContent = props => {
    return <Component t={t} {...props} />;
  };
  return <Route {...rest} render={renderContent} />;
};
export default ClientRoute;
