import React, { useState, useEffect } from "react";

import { useHistory, useLocation } from "react-router-dom";

import PropTypes from "prop-types";
import CssBaseline from "@material-ui/core/CssBaseline";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import AdminOrderMenu from "./AdminOrderMenu";
import Buttoni from "../components/Buttoni";
import Typography from "@material-ui/core/Typography";
import SectionOrderSettings from "./SectionOrderSettings";
import SectionVehicleInformation from "./SectionVehicleInformation";
import SectionCustomerInformation from "./SectionCustomerInformation";
import SectionWorkInformation from "./SectionWorkInformation";

import { sendOrder, checkIsDisabled } from "../utils";

import {
  requiredFieldsEndUser,
  requiredFieldsCompany,
  CUSTOMER_SETTINGS,
  WORK_INFORMATION,
} from "../constants";

const styles = () => ({
  root: {
    boxShadow: "0 0 20px rgba(0, 0, 0, 0.2)",
  },
  section: {
    padding: 20,
  },
  typography: {
    fontSize: "50px",
  },
  comment: {
    width: "100%",
  },
});

const AdminOrderForm = ({ classes, t, user }) => {
  const [state, setState] = useState({});
  const [customerType, handleCustomerType] = useState("consumer");

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    handleCustomerType("consumer");
    handleChange("hak_created_by", user);
  }, []);
  console.log({ XXX: state });
  const isDisabled =
    customerType === "consumer"
      ? checkIsDisabled(requiredFieldsEndUser(), {
          ...state[CUSTOMER_SETTINGS],
          ...state[WORK_INFORMATION],
        })
      : checkIsDisabled(requiredFieldsCompany(), {
          ...state[CUSTOMER_SETTINGS],
          ...state[WORK_INFORMATION],
        });

  const handleChange = (label, value) => {
    setState({ ...state, [label]: value });
  };

  const handleSubmitForm = () => {
    sendOrder(state, "api/orders/admin-order")
      .then((response) => {
        handleFormSuccess(response);
      })
      .catch((error) => handleFormError(error));
  };

  const handleFormError = (error) => {
    history.push(`${location.pathname}/error-message`);
  };

  const handleFormSuccess = (response) => {
    history.push(`${location.pathname}/confirmation-message`);
  };

  return (
    <div data-test>
      <CssBaseline>
        <Grid justify="center" alignItems="center" container direction="column">
          <Grid xs={10} container className={classes.root}>
            <AdminOrderMenu t={t} user={user} />
            <div style={{ margin: "0 0 40 0" }}>
              <Grid item xs={12} className={classes.section}>
                <SectionOrderSettings
                  t={t}
                  classes={classes}
                  title={"TILAUKSEN ASETUKSET"}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} className={classes.section}>
                <SectionVehicleInformation
                  t={t}
                  classes={classes}
                  title={"AJONEUVON TIEDOT"}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} className={classes.section}>
                <SectionCustomerInformation
                  t={t}
                  classes={classes}
                  title={"ASIAKAS TIEDOT"}
                  onChange={handleChange}
                  onChangeCustumerType={handleCustomerType}
                />
              </Grid>
              <Grid item xs={12} direction="row" className={classes.section}>
                <SectionWorkInformation
                  t={t}
                  classes={classes}
                  title={"TILAUKSEN TIEDOT"}
                  onChange={handleChange}
                />
              </Grid>
              <Grid
                xs={3}
                style={{
                  marginLeft: 30,
                }}
              >
                <Typography style={{ fontSize: 8 }}>
                  Huom. * merkityt kentät ovat pakollisia
                </Typography>
              </Grid>
              <Buttoni
                t={t}
                onChange={handleSubmitForm}
                color={"primary"}
                disabled={isDisabled}
              >
                {"Lähetä tilaus"}
              </Buttoni>
            </div>
          </Grid>
        </Grid>
      </CssBaseline>
    </div>
  );
};

AdminOrderForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AdminOrderForm);
