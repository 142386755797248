import React, { useEffect } from "react";
import { geolocated } from "react-geolocated";
import { getAddressFromLatLng } from "../utils";

const GeoLocation = props => {
  const {
    isEnabled,
    isGeolocationEnabled,
    isGeolocationAvailable,
    coords,
    onChange
  } = props;

  useEffect(() => {
    if (coords && isGeolocationAvailable && isEnabled) {
      (async () => {
        const geoLatLng = {
          lat: coords.latitude,
          lng: coords.longitude
        };
        const streetAddress = await getAddressFromLatLng(geoLatLng);
        onChange(geoLatLng, streetAddress);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGeolocationAvailable, coords, isEnabled]);

  return !isGeolocationAvailable ? (
    <div style={{ color: "red" }}>Selaimesi ei tue paikannusta</div>
  ) : !isGeolocationEnabled ? (
    <div style={{ color: "red" }}>Sinun pitää aktivoida paikkannus</div>
  ) : coords ? (
    <div style={{ color: "green" }}>Paikannus onnistui</div>
  ) : (
    <div style={{ color: "orange" }}>Yritetään paikantaa&hellip; </div>
  );
};

export default geolocated({
  positionOptions: {
    enableHighAccuracy: false
  },
  userDecisionTimeout: 35000
})(GeoLocation);
