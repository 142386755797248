import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import FormHelperText from "@material-ui/core/FormHelperText";
import TextField from "@material-ui/core/TextField";
import { REQUIRED } from "../constants";

const styles = theme => ({
  textField: {
    display: "flex",
    wrap: "wrap",
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit
  },
  errorHelper: {
    color: "red"
  }
});
const SingleTextField = props => {
  const {
    classes,
    label,
    helperText,
    validateText,
    onChange,
    required,
    currentValue,
    parser,
    validator,
    id,
    type
  } = props;
  const [value, handleValue] = useState("");
  const [error, handleError] = useState(false);

  useEffect(() => {
    handleValue(currentValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentValue]);

  const handleTextInput = e => {
    const value = e.target.value;
    let text = parser(value);
    if (required) {
      handleValidate(text);
    }
    const passedLabel = id ? id : label;
    onChange(passedLabel, text);
    handleValue(text);
  };

  const handleValidate = value => {
    if (!validator(value)) {
      handleError(true);
    }
  };

  return (
    <div>
      <TextField
        required={required}
        id={`test-textfield-${label}`.replace(/ /g, "-")}
        label={label}
        value={value}
        className={classes.textField}
        type={type}
        onChange={handleTextInput}
        helperText={helperText}
      />
      {error && (
        <FormHelperText className={classes.errorHelper}>
          {validateText}
        </FormHelperText>
      )}
    </div>
  );
};

SingleTextField.propTypes = {
  classes: PropTypes.object.isRequired,
  currentValue: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  error: PropTypes.bool,
  parser: PropTypes.func,
  validateText: PropTypes.string,
  type: PropTypes.string
};

SingleTextField.defaultProps = {
  parser: e => e,
  validator: e => e,
  validateText: REQUIRED,
  id: null,
  label: null,
  type: "string"
};

export default withStyles(styles)(SingleTextField);
