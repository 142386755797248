import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { required } from "glamor";

import fi_lang from "./lang/fi";
// the translations
// (tip move them in a JSON file and import them)
const resources = {
  fi: {
    translation: fi_lang
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "fi",
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
