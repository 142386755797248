export const cardStyle = {
  margin: 5,
  width: "100%",
  maxHeight: 220
};

export const titleStyle = {
  color: "black",
  fontWeight: "bold",
  fontSize: 20
};

export const descriptionStyle = {
  fontSize: 18
};

export const formLabel = {
  color: "#333",
  fontSize: 20,
  fontWeight: "bold"
};
