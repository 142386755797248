import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import CreateIcon from "@material-ui/icons/Create";
import Flexbox from "flexbox-react";

const defaultStyle = (theme) => ({
  root: {
    color: "#337ab7",
    fontSize: 12,
    cursor: "pointer",
  },
  icon: {
    margin: theme.spacing.unit,
    fontSize: 12,
    cursor: "pointer",
  },
  textField: {
    margin: theme.spacing.unit,
  },
});

const Comment = (props) => {
  const [value, handleValue] = useState("");
  const [active, handleActive] = useState(false);

  const {
    classes,
    label,
    id,
    helperText,
    onChange,
    title,
    showIconAndLabel,
    styles,
  } = props;

  useEffect(() => {
    const initialValue = props.value ? value : "";
    handleValue(initialValue);
    if (!!props.active) {
      handleActive(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTextInput = (e) => {
    const inputValue = e.target.value;
    const passedLabel = !!id ? id : label;
    onChange(passedLabel, inputValue);
    handleValue(inputValue);
  };

  return (
    <div className={classes.root}>
      {!!showIconAndLabel && (
        <Flexbox alignItems="center">
          <CreateIcon className={classes.icon} />
          <Typography
            className={classes.root}
            onClick={() => handleActive(!active)}
          >
            {title}
          </Typography>
        </Flexbox>
      )}
      {!!active && (
        <TextField
          id={`text-area-field-${id}`}
          label={label}
          value={value}
          multiline
          rows="4"
          className={classes.textField}
          style={{
            ...styles.input,
          }}
          //margin="normal"
          variant="outlined"
          onChange={handleTextInput}
          helperText={helperText}
        />
      )}
    </div>
  );
};

Comment.propTypes = {
  classes: PropTypes.object.isRequired,
  active: PropTypes.bool,
  showIconAndLabel: PropTypes.bool,
  styles: PropTypes.object,
};

Comment.defaultProps = {
  active: false,
  showIconAndLabel: true,
  id: null,
  styles: {},
};

export default withStyles(defaultStyle)(Comment);
