import a_vakuutus from "./images/a_vakuutus.png";
import aktia from "./images/aktia.png";
import fennia from "./images/fennia.png";
import folksam from "./images/folksam.jpg";
import if_vakuutus from "./images/if.png";
import lahitapiola from "./images/lahitapiola.png";
import op from "./images/op.png";
import pohjantahti from "./images/pohjantahti.jpg";
import pop_vakuutus from "./images/pop_vakuutus.png";
import protector from "./images/protector-logo.jpg";
import saastopankki from "./images/saastopankin-vakuutukset-logo.png";
import tryg from "./images/tryg.png";
import turva from "./images/turva.png";
import volvia from "./images/volvia.png";
import { ReactComponent as TekninenVika } from "./SVG/tekninen_vika.svg";
import { ReactComponent as Rengasrikko } from "./SVG/rengasrikko.svg";
import { ReactComponent as Kolari } from "./SVG/kolari.svg";
import { ReactComponent as UlosajoTaiJuuttuminen } from "./SVG/ulosajo_tai_juuttuminen.svg";
import { ReactComponent as MuuSyy } from "./SVG/muu_syy.svg";

export const GA_ROOT_PATH = "tilauslomake";

export const PATH_PAGE_NOT_FOUND = "404";
export const PATH_LOGIN = "/login";
export const PATH_ROOT = "/";
export const PATH_ADMIN_ORDER_FORM = "/admin";
export const PATH_ADMIN_ORDER_LIST = "/admin-order-list";
export const PATH_ADMIN_PARTNER_LIST = "/admin-partner-list";
export const PATH_ADMIN_ORDER_CONFIRMATION_MESSAGE =
  "/admin/confirmation-message";
export const PATH_ADMIN_ORDER_ERROR_MESSAGE = "/admin/error-message";

export const GOOGLE_API_KEY = "AIzaSyBZa_nTG1bxGmQCf3s_qVNMFIKPvz0oLkw&v=3.exp";
export const GOOGLE_API_URL = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&libraries=geometry,drawing,places`;
export const API_URL = process.env.REACT_APP_SERVER_URL;
export const API_URL_LOGIN = `${API_URL}/auth/login`;
export const API_URL_LOGOUT = `${API_URL}/auth/logout`;

export const AUTH_WAITING = "authWaiting";
export const AUTH_ACCEPTED = "authAccepted";
export const AUTH_REJECTED = "authRejected";

/**
 * List of table fields in admin partner table
 */

export const FIELD_COMPANY_NAME = "name";
export const FIELD_COMPANY_ID = "innovoice_company_id";
export const FIELD_BRANCH_ID = "innovoice_branch_id";
export const FIELD_BRANCH = "innovoice_branch_name";
export const FIELD_PHONE = "phone";
export const FIELD_EMAIL = "email";
export const FIELD_NOTIFICATION_SMS = "notification_sms";
export const FIELD_NOTIFICATION_EMAIL = "notification_email";
export const FIELD_FROM_ADDRESS = "address";
export const FIELD_COORDINATES = "coordinates";
export const FIELD_COORDINATES_X = "x";
export const FIELD_COORDINATES_Y = "y";
export const FIELD_RANGE = "range";
export const FIELD_UPDATE_ROW = "updateRow";
export const FIELD_MODIFY_ROW = "modifyRow";
export const FIELD_DELETE_ROW = "deleteRow";

/**
 * Customer form enums, key value pairs. This should be built into one json file at some point
 */

/** Defined main section key */
export const QUESTION_AIKATAULU = "timetable";
export const QUESTION_SIJAINTI = "location";
export const QUESTION_TOIMTIUS = "delivery";
export const QUESTION_AUTON_TIEDOT = "vehicleDetails";
export const QUESTION_YHTEYSTIEDOT = "contactDetails";
export const QUESTION_YHTEENVETO = "summary";
export const QUESTION_MIKA_VIKA = "towingReason";
export const QUESTION_KUKA_MAKSAA = "payer";

export const REQUIRED = "Vaadittu kenttä";
export const IN_RANGE = "inRange";
export const QUESTION = "kysymys";
export const ANSWER = "vastaus";
export const FROM = "from";
export const TO = "to";
export const FOLLOW_UP_QUESTION = "jatkoKysymys";
export const FOLLOW_UP_ANSWER = "jatkoVastaus";
export const DATE_TIME = "dateTime";
export const CURRENT_VALUE = "currentValues";
export const ALOITA_PAIKANNUS = "Aloita paikannus";
export const LISÄTIETOA = "Lisätietoa";
export const MARKER = "Koordinaatit";
export const STREET_ADDRESS = "Katuosoite";

export const DELIVERY_OPTIONS = [
  {
    value: "Heti",
  },
  {
    value: "Voin odottaa muutaman tunnin",
  },
  {
    value: "Voin odottaa iltaan tai huomiseen",
  },
  {
    value: "Ennakkotilaus",
  },
];

export const YES = "Kyllä";
export const NO = "En";

export const MINNE_AUTO_VIEDÄÄN = "Tiedätkö minne auto viedään";
export const KORJAAMON_SIJAINTI =
  "Onko tiedossa paikka, johon ajoneuvo viedään?";
export const AUTON_SIJAINTI = "Auton sijainti";
export const REKISTERITIEDOT = "Rekisterinumero";
export const MERKKI_MALLI = "Auton merkki ja malli";
export const LISÄTIEDOT = "Lisätiedot";
export const AVAINTEN_SAATAVUUS = "Missä ajoneuvon avaimen sijaitsevat?";
export const ETUNIMI = "Etunimi";
export const SUKUNIMI = "Sukunimi";
export const PUHELINNUMERO = "Puhelinnumero";
export const SÄHKÖPOSTI = "Sähköposti";
export const CARD = "CardSelection";
export const RADIO_FIELD = "RadioField";
export const INFO = "TextInfo";
export const MULTI_TEXT_FIELD = "MultiTextField";
export const insuranceCompanies = [
  { value: "A-Vakuutus", img: a_vakuutus },
  { value: "Aktia", img: aktia },
  { value: "Fennia", img: fennia },
  { value: "Folksam", img: folksam },
  { value: "IF", img: if_vakuutus },
  { value: "Lähitapiola", img: lahitapiola },
  { value: "OP Vakuutus", img: op },
  { value: "Pohjantahti", img: pohjantahti },
  { value: "POP Vakuutus", img: pop_vakuutus },
  { value: "Protector", img: protector },
  { value: "Säästöpankin vakuutukset", img: saastopankki },
  { value: "Tryg", img: tryg },
  { value: "Turva", img: turva },
  { value: "Volvia", img: volvia },
  { value: "Joku muu vakuutusyhtiö", img: "" },
  { value: "En muista vakuutusyhtiötä", img: "" },
];
export const PLACEHOLDER_CAR_LOCATION = "esim Mannerheimintie 8, Helsinki";
export const problemList = [
  {
    key: "technical",
    title: "Tekninen vika",
    image: TekninenVika,
    description:
      "Akku loppu, moottori ei käynnisty tai muu tekniikkaan liittyvä vika.",
    question: "Valitse tarkennus",
    subTask: [
      {
        value: "Ajoneuvo ei käynnisty",
        question: "Mitä tapahtuu, kun yrität käynnistää?",
        subTask: [
          {
            value: "Starttimoottori pyörii, mutta auto ei käynnisty",
            component: RADIO_FIELD,
          },
          { value: "Starttimoottori ei pyöri", component: RADIO_FIELD },
          { value: "Starttimoottori pyörii hitaasti", component: RADIO_FIELD },
          {
            value: "Autosta kuuluu nakutus tai tikitys, kun yritän käynnistää",
            component: RADIO_FIELD,
          },
        ],
      },
      {
        value: "Ajoneuvo sammui ajossa",
        question: "Syttyikö mittaristoon merkkivaloja?",
        subTask: [
          { value: "Kyllä", component: RADIO_FIELD },
          { value: "Ei", component: RADIO_FIELD },
          { value: "Kerro omin sanoin enemmän", component: MULTI_TEXT_FIELD },
        ],
      },
      {
        value: "Muut oireet tai merkkivalot",
        question: "Kumpi on kyseessä?",
        subTask: [
          { value: "Merkkivalo", component: RADIO_FIELD },
          { value: "Oire", component: RADIO_FIELD },
          { value: "Kerro omin sanoin enemmän", component: MULTI_TEXT_FIELD },
        ],
      },
    ],
  },
  {
    key: "tireBroke",
    title: "Rengasrikko",
    image: Rengasrikko,
    description:
      "Renkaan puhjetessa rengas paikataan paikan päällä tai toimitetaan vararengas tilalle, jotta matka voi jatkua. Tarvittaessa viemme ajoneuvosi rengasliikkeeseen.",
    question: "Miten huomasit asian?",
    subTask: [
      {
        value: "Osuin johonkin ja rengas hajosi",
        question: "Onko autossa vararengas",
        subTask: [
          { value: "Kyllä", component: RADIO_FIELD },
          { value: "Ei", component: RADIO_FIELD },
          { value: "En tiedä", component: RADIO_FIELD },
        ],
      },
      {
        value: "Yksi rengas tyhjä, huomasin lähtiessä liikkeelle",
        question: "Onko autossa vararengas",
        subTask: [
          { value: "Kyllä", component: RADIO_FIELD },
          { value: "Ei", component: RADIO_FIELD },
          { value: "En tiedä", component: RADIO_FIELD },
        ],
      },
      {
        value: "Huomasin ajossa renkaan olevan tyhjä",
        question: "Onko autossa vararengas",
        subTask: [
          { value: "Kyllä", component: RADIO_FIELD },
          { value: "Ei", component: RADIO_FIELD },
          { value: "En tiedä", component: RADIO_FIELD },
        ],
      },
    ],
  },
  {
    key: "accident",
    title: "Kolari",
    image: Kolari,
    description: "Kolaritilanteessa hinaamme ajoneuvosi korjaamolle.",
    question: "Mihin osuit",
    subTask: [
      {
        value: "Esteeseen",
        component: RADIO_FIELD,
      },
      {
        value: "Eläimeen",
        component: RADIO_FIELD,
      },
      {
        value: "Toiseen ajoneuvoon",
        question: "Tarvitseeko toinen osapuoli hinauksen?",
        subTask: [
          { value: "Kyllä", component: RADIO_FIELD },
          { value: "Ei", component: RADIO_FIELD },
        ],
      },
    ],
  },
  {
    key: "driveOff",
    title: "Ulosajo tai juuttuminen",
    image: UlosajoTaiJuuttuminen,
    description:
      "Usein ylösveto riittää, jos ajoneuvosi on jumittunut tai ajanut ulos.",
    question: "Kumpi on kyseessä",
    subTask: [
      {
        value: "Ulosajo",
        question: "Näkyykö autossa vaurioita?",
        subTask: [
          { value: "Kyllä", component: RADIO_FIELD },
          { value: "Ei", component: RADIO_FIELD },
        ],
      },
      {
        value: "Juuttuminen",
        question: "Näkyykö autossa vaurioita?",
        subTask: [
          { value: "Kyllä", component: RADIO_FIELD },
          { value: "Ei", component: RADIO_FIELD },
        ],
      },
    ],
  },
  {
    key: "other",
    title: "Muu syy",
    image: MuuSyy,
    description:
      "Tulipalo, avaimien hukkuminen, polttoaineen loppuminen tai jokin muu.",
    question: "",
    subTask: [
      { value: "Varkaus", component: RADIO_FIELD },
      { value: "Ilkivalta", component: RADIO_FIELD },
      { value: "Tulipalo", component: RADIO_FIELD },
      { value: "Polttoaineen loppuminen", component: RADIO_FIELD },
      { value: "Avainten jääminen lukittuun autoon", component: RADIO_FIELD },
      { value: "Avainten katoaminen", component: RADIO_FIELD },
      { value: "Raskaan kaluston hinaus", component: RADIO_FIELD },
    ],
  },
];

export const deliveryList = [
  {
    value: "Kyllä",
    description: "",
    subTask: "Sijainti",
  },
  {
    value: "En",
    description: "",
  },
];

export const payerList = [
  {
    value: "Vakuutusyhtiö",
    question: "Valitse vakuutusyhtiö",
    description: "",
    component: CARD,
    subTask: insuranceCompanies,
  },
  {
    value: "Maksan itse",
    question: "Maksuvelvollisuus",
    description: "",
    component: INFO,
    subTask: [
      {
        question: "Maksuvelvollisuus",
        value:
          "Tilaukseen sisältyy aina maksuvelvollisuus. Palvelu maksetaan hinaus- tai tiepalveluauton kuljettajalle. Laskulla maksaminen vaatii tilauskeskuksessamme luottotietojen tarkistamista. Laskulla voi maksaa yli 18 -vuotias Suomessa asuva luottokelpoinen henkilö. Laskutustiedot määräytyvät Väestörekisterin tietojen mukaan, joten osoitetiedot on oltava löydettävissä.",
      },
      {
        question: "Peruutusoikeus",
        value:
          "Tilauksella ei ole peruutusoikeutta, sillä kyse on kiireellisestä työstä paikassa, johon yritys saapuu kuluttajan pyynnöstä, joko välittömästi tai sovittuna ajankohtana. Sinulla on kuitenkin mahdollisuus peruuttaa tilaus välittömästi sen tekemisen jälkeen, jos tilausta ei ole vielä ehditty välittämään hinaus- tai tiepalveluautolle. Peruutus on tehtävä aina puhelimitse numeroon 09 350 8550. Numero on myös tilausvahvistuksessa. Mikäli hinaus- tai tiepalveluauto on jo saapunut kohteeseen, on kuljettaja oikeutettu perimään siitä korvauksen.",
      },
    ],
  },
];

/**
 * ADMIN CONSTANTS
 */

export const contractors = [
  {
    id: 2,
    innovoice_company_id: "2040",
    innovoice_branch_id: "104",
    name: "Hinausautokeskus Oy - Porvoo",
    address: "Lakkapolku 54 06100 Porvoo",
    coordinates: { x: 60.414187, y: 25.656004 },
  },
  {
    id: 3,
    innovoice_company_id: "2040",
    innovoice_branch_id: "102",
    name: "Hinausautokeskus Oy - Helsinki",
    address: "Juhtatie 2, 00750 Helsinki",
    coordinates: { x: 60.282625, y: 25.035495 },
  },
  {
    id: 1,
    name: "Järvenpaan Vika-Auto Oy",
    innovoice_branch_id: "",
    innovoice_company_id: "1268",
    address: "Puurtajankatu 10, 04440 Järvenpää",
    coordinates: { x: 60.502712, y: 25.075951 },
  },
];

export const COORDINATES = "coordinates";
export const ADDRESS = "address";
export const CUSTOMER_TYPE = "type";
export const CUSTOMER_TYPE_COMPANY = "company";
export const CUSTOMER_TYPE_CONSUMER = "consumer";
export const ORDER_SETTINGS = "orderSettings";
export const VEHICLE_INFORMATION = "vehicleInformation";
export const CUSTOMER_SETTINGS = "customer";
export const WORK_INFORMATION = "workInformation";
export const PARTNER = "partner";

export const orderTypeLabelValue = {
  value: "serviceType",
  label: "Tilaus tyyppi",
};

export const serviceTypeList = [
  { value: 0, label: "Hinaus" },
  { value: 1, label: "Tiepalvelu" },
  { value: 2, label: "Akkupalvelu" },
  { value: 3, label: "Puhelinneuvonta" },
];

export const priorityLabelValue = {
  value: "priority",
  label: "Tilaus tyyppi",
};

export const priorityList = [
  { value: "Hinaus", label: "Hinaus" },
  { value: "Tiepalvelu", label: "Tiepalvelu" },
  { value: "Akkupalvelu", label: "Akkupalvelu" },
  { value: "Puhelinneuvonta", label: "Puhelinneuvonta" },
];

export const orderVisibilityLabelValue = {
  value: "visibility",
  label: "näkyvyys",
};

export const orderVisibilityList = [
  { value: "open", label: "Avoin, näkyy kaikille" },
  { value: "restricted", label: "Avoin, näkyy pääkäyttäjälle" },
];

export const vehicleRegNoLabelValue = {
  label: "Rekisteritunnus",
  value: "vehicleRegNo",
};

export const insuranceCompanyLabelValue = {
  label: "Vakuutusyhtiö",
  value: "insuranceCompany",
};

export const insuranceCompanyList = [
  { value: "A-Vakuutus", label: "A-Vakuutus" },
  { value: "Aktia", label: "Aktia" },
  { value: "Fennia", label: "Fennia" },
  { value: "Folksam", label: "Folksam" },
  { value: "IF", label: "IF" },
  { value: "Lähitapiola", label: "Lähitapiola" },
  { value: "OP Vakuutus", label: "OP Vakuutus" },
  { value: "Pohjantahti", label: "Pohjantahti" },
  { value: "POP Vakuutus", label: "POP Vakuutus" },
  { value: "Protector", label: "Protector" },
  { value: "Säästöpankin vakuutukset", label: "Säästöpankin vakuutukset" },
  { value: "Tryg", label: "Tryg" },
  { value: "Turva", label: "Turva" },
  { value: "Volvia", label: "Volvia" },
  { value: "Joku muu vakuutusyhtiö", label: "Joku muu vakuutusyhtiö" },
  { value: "En muista vakuutusyhtiötä", label: "En muista vakuutusyhtiötä" },
];

export const vehicleOwnerLabelValue = {
  label: "Ajoneuvon omistaja",
  value: "vehicleOwner",
};

export const vehicleHolderLabelValue = {
  label: "Ajoneuvon haltija",
  value: "vehicleHolder",
};

export const vehicleBrandLabelValue = {
  label: "Ajoneuvon merkki",
  value: "vehicleBrand",
};

export const vehicleModelLabelValue = {
  label: "Ajoneuvon malli",
  value: "vehicleModel",
};

export const userLastNameLabelValue = {
  label: "Sukunimi",
  value: "lastname",
};
export const userFirstNameLabelValue = {
  label: "Etunimet",
  value: "firstname",
};
export const userPhoneNumberLabelValue = {
  label: "Puhelinnumero",
  value: "phone",
};
export const userEmailLabelValue = {
  label: "Sähköposti",
  value: "email",
};

export const orderDateLabelValue = {
  label: "Tilauksen päivänmäärä",
  value: "requestedTowingDate",
};

export const orderTimeLabelValue = {
  label: "Tilauksen päivänmäärä",
  value: "requestedTowingTime",
};

export const towingReasonLabelValue = {
  label: "Hinauksen syy",
  value: "towingReason",
};

export const towingReasonList = [
  { label: "Apuvirta", value: "Apuvirta" },
  { label: "Hirvieläinkolari", value: "Hirvieläinkolari" },
  { label: "Kolari", value: "Kolari" },
  { label: "Korjaus paikalla", value: "Korjaus paikalla" },
  { label: "Oven avaus", value: "Oven avaus" },
  { label: "Siirtokuljetus", value: "Siirtokuljetus" },
  { label: "Sähkövika", value: "Sähkövika" },
  { label: "Tekninen vika", value: "Tekninen vika" },
  { label: "Tiellenosto", value: "Tiellenosto" },
  { label: "Ulosajo", value: "Ulosajo" },
  { label: "Varkaus / ilkivalta", value: "Varkaus / ilkivalta" },
  { label: "Muu", value: "Muu" },
];

export const orderReferenceLabelValue = {
  label: "Tilauksen viite",
  value: "reference",
};

export const additionalInformationLabelValue = {
  label: "Lisätietoa",
  value: "additionalInfo",
};

export const descriptionFromLabelValue = {
  label: "Lisätietoa nouto",
  value: "descriptionFrom",
};

export const descriptionToLabelValue = {
  label: "Lisätietoa toimitus",
  value: "descriptionTo",
};

export const notesLabelValue = {
  label: "Muistiinpano",
  value: "notes",
};

export const fromLabelValue = {
  label: "Mistä *",
  value: "from",
};

export const toLabelValue = {
  label: "Mihin",
  value: "to",
};

export const companyCompanyNameLabelValue = {
  label: "Yrityksen nimi",
  value: "companyName",
};

export const companyContactPersonLabelValue = {
  label: "Yrityksen yhteyshenkilö",
  value: "contactPerson",
};

export const companyPhoneLabelValue = {
  label: "Yhteyshenkilön puhelin",
  value: "phone",
};

export const companyContactPersonEmailLabelValue = {
  label: "Yhteyshenkilön sähköposti",
  value: "contactPersonEmail",
};

export const requiredFieldsEndUser = () =>
  [
    //orderTypeLabelValue,
    //orderVisibilityLabelValue
    // vehicleOwnerLabelValue,
    // vehicleHolderLabelValue,
    // vehicleBrandLabelValue,
    // vehicleModelLabelValue,
    userLastNameLabelValue,
    userFirstNameLabelValue,
    userPhoneNumberLabelValue,
    //userEmailLabelValue
    // orderDateTimeLabelValue,
    // towingReasonLabelValue,
    // orderReferenceLabelValue,
    // additionalInformationLabelValue,
    // notesLabelValue,
    fromLabelValue,
    // toLabelValue
  ].map((field) => field.value);

export const requiredFieldsCompany = () =>
  [
    //orderTypeLabelValue,
    //orderVisibilityLabelValue
    // vehicleOwnerLabelValue,
    // vehicleHolderLabelValue,
    // vehicleBrandLabelValue,
    // vehicleModelLabelValue,
    companyCompanyNameLabelValue,
    companyContactPersonLabelValue,
    companyPhoneLabelValue,
    fromLabelValue,
    //userEmailLabelValue
    // orderDateTimeLabelValue,
    // towingReasonLabelValue,
    // orderReferenceLabelValue,
    // additionalInformationLabelValue,
    // notesLabelValue,
    // fromLabelValue,
    // toLabelValue
  ].map((field) => field.value);
