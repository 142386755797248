import React, { useEffect, useContext, useState } from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import Modal from "@material-ui/core/Modal";
import { CXContext } from "../context/Context";
import Button from "@material-ui/core/Button";
import CheckCircle from "@material-ui/icons/CheckCircle";
import NegativeCircle from "@material-ui/icons/ErrorRounded";

const styles = theme => ({
  paper: {
    fontSize: 14,
    fontWeight: "normal",
    position: "absolute",
    left: "50%",
    top: "40%",
    transform: "translate(-50%, -30%)",
    width: "45%",
    height: "50%",
    backgroundColor: "white",
    border: "2px red solid",
    textAlign: "center",
    padding: 30
  },
  buttons: {
    zIndex: 9999,
    width: "100%",
    maxHeight: 100,
    display: "flex",
    position: "fixed",
    bottom: 0,
    backgroundColor: "white",
    alignItems: "center",
    marginTop: 5
  },
  button: {
    marginTop: theme.spacing.unit * 3,
    marginLeft: theme.spacing.unit,
    boxShadow: "none"
  },
  buttonContainer: {
    margin: "50px 0",
    width: "100%"
  },
  icon: {
    color: "red",
    fontSize: 60
  }
});

const MessageModal = props => {
  const { classes, isOpen, content, header } = props;
  //const context = useContext(CXContext);
  //const { formSubmitted, formError, handleProcessComplete } = context;

  // getModalStyle is not a pure function, we roll the style only on the first render
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!!isOpen) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [isOpen]);

  const handleClose = () => {
    setOpen(false);
    //handleProcessComplete();
  };

  return (
    <div>
      <Modal
        disableAutoFocus
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={handleClose}
      >
        <div className={classes.paper}>
          <NegativeCircle className={classes.icon} />
          <p style={{ fontSize: 18, fontWeight: "bold" }}>{header}</p>
          <div>{content}</div>
          <Button
            id="phase-button-submit"
            variant="contained"
            color="primary"
            onClick={() => {
              handleClose();
            }}
            className={classes.button}
          >
            Ok
          </Button>
        </div>
      </Modal>
    </div>
  );
};

MessageModal.prototypes = {
  content: PropTypes.element.isRequired,
  header: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired
};

export default withStyles(styles)(MessageModal);
