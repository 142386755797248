import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import * as R from "ramda";
import SingleTextField from "../components/SingleTextField";

import {
  FIELD_BRANCH,
  FIELD_COORDINATES,
  FIELD_COORDINATES_X,
  FIELD_COORDINATES_Y,
  FIELD_RANGE
} from "../constants";

const styles = {
  root: {
    backgroundColor: "white"
  }
};

// SingleTextField
const AdminModalContentPartnerDetails = ({
  listOfFields,
  record,
  classes,
  t,
  onChange
}) => {
  const handleChange = (label, value) => {
    onChange({ label, value });
  };

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        {listOfFields.map(field => {
          if (field === FIELD_COORDINATES_X)
            return (
              <SingleTextField
                currentValue={R.pathOr("", [FIELD_COORDINATES, "x"], record)}
                parser={e => e}
                type="number"
                onChange={(label, value) => handleChange(field, value)}
                label={t(field)}
              />
            );
          if (field === FIELD_COORDINATES_Y)
            return (
              <SingleTextField
                currentValue={R.pathOr("", [FIELD_COORDINATES, "y"], record)}
                parser={e => e}
                type="number"
                onChange={(label, value) => handleChange(field, value)}
                label={t(field)}
              />
            );
          if (field === FIELD_RANGE)
            return (
              <SingleTextField
                currentValue={R.propOr("", field, record)}
                parser={e => e}
                type="number"
                onChange={(label, value) => handleChange(field, value)}
                label={t(field)}
              />
            );
          else {
            return (
              <SingleTextField
                currentValue={R.propOr("", field, record)}
                parser={e => e}
                onChange={(label, value) => handleChange(field, value)}
                label={t(field)}
              />
            );
          }
        })}
      </Grid>
    </Grid>
  );
};

AdminModalContentPartnerDetails.prototypes = {
  record: PropTypes.object,
  classes: PropTypes.object,
  t: PropTypes.func.isRequired
};

AdminModalContentPartnerDetails.defaultProps = {
  record: {}
};

export default withStyles(styles)(AdminModalContentPartnerDetails);
