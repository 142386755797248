import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import CheckboxSelection from "./components/CheckboxSelection";
import SingleTextField from "./components/SingleTextField";
import MultiTextField from "./components/MultiTextField";
import { CXContext } from "./context/Context";
import PhaseButtons from "./PhaseButtons";
import {
  checkIsDisabled,
  registrationPlateParser,
  firstLetterToUpperCaseParse
} from "./utils";

import {
  REKISTERITIEDOT,
  MERKKI_MALLI,
  LISÄTIEDOT,
  AVAINTEN_SAATAVUUS
} from "./constants";

import { formLabel } from "./styles";

const requiredFields = [REKISTERITIEDOT, MERKKI_MALLI];

const styles = theme => ({
  root: {
    display: "flex"
  },
  formControl: {
    margin: theme.spacing.unit * 3
  },
  group: {
    margin: `${theme.spacing.unit}px 0`
  },
  formLabel: formLabel
});

const AutonTiedot = ({ classes, pageQuestion }) => {
  const [state, setState] = useState({});

  const context = useContext(CXContext);
  const { mainJson } = context;

  useEffect(() => {
    if (mainJson[pageQuestion]) {
      const prevState = mainJson[pageQuestion];

      setState(prevState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (label, value) => {
    setState({ ...state, [label]: value });
  };

  const isDisabled = checkIsDisabled(requiredFields, state);

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <FormControl component="fieldset" className={classes.formControl}>
          <FormLabel classes={{ root: classes.formLabel }} component="legend">
            {pageQuestion}
          </FormLabel>
          <SingleTextField
            currentValue={state[REKISTERITIEDOT]}
            parser={registrationPlateParser}
            required={true}
            onChange={handleChange}
            label={REKISTERITIEDOT}
            helperText={"esim. ABC-123"}
          />
          <SingleTextField
            parser={firstLetterToUpperCaseParse}
            currentValue={state[MERKKI_MALLI]}
            required={true}
            onChange={handleChange}
            label={MERKKI_MALLI}
            helperText={"Esim. Volkswagen Golfiin"}
          />
          <CheckboxSelection
            onChange={handleChange}
            currentValue={state[LISÄTIEDOT]}
            label={LISÄTIEDOT}
            options={{
              Neliveto: false,
              "Automaattivaihteisto": false,
              "Ajoneuvo on yrityksen nimissä": false
            }}
          />
          <MultiTextField
            currentValue={state[AVAINTEN_SAATAVUUS]}
            onChange={handleChange}
            label={AVAINTEN_SAATAVUUS}
            helperText="Esim. renkaan päällä, lähimmän huoltoaseman tai kaupan kassalla, olen itse paikalla, muu, missä?"
          />
        </FormControl>
      </Grid>
      <PhaseButtons
        isDisabled={isDisabled}
        formValues={{
          [pageQuestion]: {
            ...state
          }
        }}
      />
    </Grid>
  );
};

AutonTiedot.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(AutonTiedot);
