import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import { API_URL_LOGIN } from "../constants";
import { ReactComponent as Logo } from "../elk-cg.svg";

const styles = () => ({
  root: {
    padding: "20px",
    borderRadius: "3px",
    minHeight: "400px",
    maxWidth: "450px",
    width: "100%",
    position: "absolute",
    top: "50%",
    right: "50%",
    transform: "translate(50%,-50%)",
    background: "#f70000",
    boxShadow: "0 0 20px rgba(0, 0, 0, 0.2)",
    color: "black"
  },
  link: {
    position: "relative",
    width: "70%",
    height: "30px"
  },
  submit: {
    margin: 20
  }
});

const Login = props => {
  const { classes, t } = props;

  return (
    <div className={classes.root}>
      <Grid
        xs={12}
        container
        justify={"center"}
        alignContent={"center"}
        alignItems="center"
        style={{ height: "inherit" }}
        direction="column"
      >
        <Grid item xs={10} style={{ textAlign: "center", margin: "0 auto" }}>
          <Logo style={{ width: "35%", padding: 20 }} />
          <Typography
            style={{
              color: "white",
              fontSize: 24,
              padding: 10
            }}
            color="textSecondary"
            gutterBottom
          >
            {t("headerLogin")}
          </Typography>
        </Grid>
        <Grid item xs={10} style={{ textAlign: "center", margin: "0 auto" }}>
          <a href={`${API_URL_LOGIN}`}>
            <Button
              className={classes.submit}
              variant="contained"
              color="primary"
            >
              {t("buttonLogin")}
            </Button>
          </a>
        </Grid>
      </Grid>
      <p
        style={{
          fontSize: "9px",
          color: "white",
          position: "absolute",
          bottom: "20px",
          textAlign: "center",
          padding: "0 30px"
        }}
      >
        {t("textHelperLogin")}
      </p>
    </div>
  );
};

Login.prototypes = {
  classes: PropTypes.object,
  t: PropTypes.func
};

Login.defaultProps = {
  t: e => e
};

export default withStyles(styles)(Login);
