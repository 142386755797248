import React from "react";
import { withStyles } from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import { map } from "ramda";
import FilterSelect from "../components/FilterSelect";

const styles = () => ({
  root: {
    width: "100%",
    backgroundColor: "#f7f4f4",
  },
  formLabel: { fontSize: 18, fontWeight: "bold", margin: 10 },
});

const AdminOrderListFilters = (props) => {
  const { classes, config, onChange, filterValues, t } = props;

  const { filters } = config;
  const filterOptions = (filter) => ({
    label: filter.label,
    value: filter.value,
    options: filter.options,
  });
  const listOfFilters = map(filterOptions, filters);

  const handleChange = (label, option) => {
    onChange(label, option);
  };

  return (
    <div className={classes.root}>
      <FormLabel component="legend" className={classes.formLabel}>
        {t("headerFilters")}
      </FormLabel>
      <Grid container xs={12} direction="row">
        {map((filterComponent) => {
          return (
            <FilterSelect
              t={t}
              label={filterComponent.label}
              onChange={(value) => handleChange(filterComponent.label, value)}
              value={filterValues[filterComponent.label]}
              options={["", ...filterComponent.options]}
            />
          );
        }, listOfFilters)}
      </Grid>
    </div>
  );
};

export default withStyles(styles)(AdminOrderListFilters);
