import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { withStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";

const styles = (theme) => ({
  formControl: {
    margin: 10,
    minWidth: 200,
  },
});

const DropDownSelect = ({ label, id, options, classes, onChange, value }) => {
  const [values, setValues] = useState({
    [label]: "",
  });

  useEffect(() => {
    if (!value) {
      value = "";
    }
    setValues({ [label]: value });
  }, [value]);

  const handleChange = (event) => {
    setValues((oldValues) => ({
      ...oldValues,
      [event.target.name]: event.target.value,
    }));

    onChange(id, event.target.value);
  };

  return (
    <FormControl className={classes.formControl}>
      <InputLabel htmlFor={id}>{label}</InputLabel>
      <Select
        data-testid={id}
        value={values[label]}
        onChange={handleChange}
        inputProps={{
          name: label,
          id: id,
        }}
      >
        {options.map((option) => (
          <MenuItem data-testid={`${id}-option`} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

DropDownSelect.propTypes = {
  options: PropTypes.array,
  label: PropTypes.string,
  id: PropTypes.string,
  classes: PropTypes.object,
  onChange: PropTypes.func,
};

DropDownSelect.defaultProps = {
  onChange: (e) => e,
};

export default withStyles(styles)(DropDownSelect);
