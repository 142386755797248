import React from "react";
import PropTypes from "prop-types";
//import { withStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { css } from "glamor";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery/unstable_useMediaQuery";
import { cardStyle, titleStyle, descriptionStyle } from "../styles";

const additionalCss = css({
  cursor: "pointer"
});

const CardSelection = props => {
  const {
    styles,
    title,
    description,
    onSelect,
    id,
    image,
    icon,
    isSelected
  } = props;

  const matches = useMediaQuery("(max-width:600px)");

  const extendedStyles = {
    card: { ...cardStyle, maxHeight: !!matches ? 80 : 220 },
    title: titleStyle,
    description: descriptionStyle
  };

  const useStyles = makeStyles(extendedStyles);

  const classes = useStyles();

  const Icon = icon;
  return (
    <Card
      id={`card-${id}`}
      {...additionalCss}
      className={classes.card}
      onClick={() => onSelect(id, title)}
      raised={isSelected}
      style={{ ...styles }}
    >
      <CardContent>
        <Grid
          container
          direction={!!matches ? "row" : "column"}
          alignItems="center"
          justify={!!matches ? "space-between" : "flex-start"}
        >
          {!!image && <img src={image} style={{ maxWidth: 100 }} />}
          {!!icon && (
            <div
              style={{
                width: "100px",
                height: "60px"
              }}
            >
              <Icon />
            </div>
          )}

          <Typography
            className={classes.title}
            color="textSecondary"
            gutterBottom
          >
            {title}
          </Typography>
          {!matches && (
            <Typography
              className={classes.description}
              color="textSecondary"
              gutterBottom
            >
              {description}
            </Typography>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

CardSelection.propTypes = {
  classes: PropTypes.object.isRequired,
  isSelected: PropTypes.bool
};

CardSelection.defaultProps = {
  isSelected: false
};

export default CardSelection;
//export default withStyles(styles)(CardSelection);
