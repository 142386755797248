import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import ExpansionSelection from "./components/ExpansionSelection";
import Grid from "@material-ui/core/Grid";
import RadioSelection from "./components/RadioSelection";
import CheckboxSelection from "./components/CheckboxSelection";
import SingleTextField from "./components/SingleTextField";
import MultiTextField from "./components/MultiTextField";
import * as R from "ramda";
import { CXContext } from "./context/Context";
import PhaseButtons from "./PhaseButtons";
import {
  checkIsDisabled,
  phoneNumberValidator,
  firstLetterToUpperCaseParse
} from "./utils";

import { ETUNIMI, SUKUNIMI, PUHELINNUMERO, SÄHKÖPOSTI } from "./constants";

import { formLabel } from "./styles";

const requiredFields = [ETUNIMI, SUKUNIMI, PUHELINNUMERO];

const styles = theme => ({
  root: {
    display: "flex"
  },
  formControl: {
    margin: theme.spacing.unit * 3
  },
  group: {
    margin: `${theme.spacing.unit}px 0`
  },
  formLabel: formLabel
});

const Yhteystiedot = ({ classes, pageQuestion }) => {
  const [state, setState] = useState({});
  const context = useContext(CXContext);
  const { mainJson } = context;

  useEffect(() => {
    if (mainJson[pageQuestion]) {
      const prevState = mainJson[pageQuestion];

      setState(prevState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (label, value) => {
    setState({ ...state, [label]: value });
  };

  const isDisabled = checkIsDisabled(requiredFields, state);

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <FormControl component="fieldset" className={classes.formControl}>
          <FormLabel classes={{ root: classes.formLabel }} component="legend">
            {pageQuestion}
          </FormLabel>
          <SingleTextField
            parser={firstLetterToUpperCaseParse}
            currentValue={state[ETUNIMI]}
            required={true}
            onChange={handleChange}
            label={ETUNIMI}
          />
          <SingleTextField
            parser={firstLetterToUpperCaseParse}
            currentValue={state[SUKUNIMI]}
            required={true}
            onChange={handleChange}
            label={SUKUNIMI}
          />
          <SingleTextField
            currentValue={state[PUHELINNUMERO]}
            validator={phoneNumberValidator}
            required={true}
            onChange={handleChange}
            label={PUHELINNUMERO}
            helperText="Puhelinnumero josta tavoitamme sinut"
            validateText="Tämä on vaadittu kenttä ja vain numero arvoja"
          />
          <SingleTextField
            currentValue={state[SÄHKÖPOSTI]}
            onChange={handleChange}
            label={SÄHKÖPOSTI}
            helperText="Ei pakollinen. Sähköpostia käytetään vain tilausvahvistuksen lähettämiseen."
          />
        </FormControl>
      </Grid>
      <PhaseButtons
        isDisabled={isDisabled}
        formValues={{
          [pageQuestion]: {
            ...state
          }
        }}
      />
    </Grid>
  );
};

Yhteystiedot.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Yhteystiedot);
