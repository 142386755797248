import React, { useContext } from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button";
import { CXContext } from "./context/Context";
import { sendOrder } from "./utils";

const styles = theme => ({
  buttons: {
    // zIndex: 9999,
    width: "100%",
    maxHeight: 100,
    display: "flex",
    position: "fixed",
    bottom: 0,
    // backgroundColor: "white",
    alignItems: "center",
    marginTop: 5
  },
  button: {
    marginTop: theme.spacing.unit * 3,
    marginLeft: theme.spacing.unit,
    boxShadow: "none"
  },
  buttonContainer: {
    margin: "50px 0",
    width: "100%"
  }
});

const PhaseButtons = ({ classes, onAction, isDisabled, formValues }) => {
  const context = useContext(CXContext);
  const {
    stepForward,
    stepBack,
    countOfSteps,
    step,
    onMergeToJson,
    FormSuccess,
    FormError
  } = context;

  const handleClick = type => {
    if (type === "back") {
      stepBack();
    } else {
      onAction();
      onMergeToJson({ ...formValues });
      stepForward();
    }
  };
  const sendOrderStep = step === countOfSteps.length - 1;
  return (
    <div className={classes.buttons}>
      {step !== 0 && (
        <div className={classes.buttonContainer}>
          <Button
            id="phase-button-back"
            onClick={() => handleClick("back")}
            className={classes.button}
          >
            Takaisin
          </Button>
          <Button
            id="phase-button-forward"
            disabled={isDisabled}
            variant="contained"
            color="primary"
            onClick={() => {
              !!sendOrderStep
                ? sendOrder(formValues, "api/orders/user-order")
                  .then(response => {
                    window.dataLayer.push({
                      'event': 'orderConfirmationEvent'
                    });
                    FormSuccess(response);
                  })
                  .catch(error => FormError(error))
                : handleClick("forward");
            }}
            className={classes.button}
          >
            {!!sendOrderStep ? "Vahvista tilaus" : "Seuraava"}
          </Button>
        </div>
      )}
    </div>
  );
};

PhaseButtons.propTypes = {
  classes: PropTypes.object.isRequired,
  onAction: PropTypes.func
};

PhaseButtons.defaultProps = {
  onAction: e => e,
  isDisabled: false
};

export default withStyles(styles)(PhaseButtons);
