import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";
import * as R from "ramda";

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  formControl: {
    margin: theme.spacing.unit * 3,
  },
});

const CheckboxSelection = (props) => {
  const { options, label, onChange, classes, currentValue } = props;

  const [state, handleValue] = useState(options);

  useEffect(() => {
    if (!!currentValue) {
      handleValue(currentValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentValue]);

  const handleChange = (select) => (event) => {
    const nextState = { ...state, [select]: event.target.checked };
    handleValue(nextState);
    onChange(label, nextState);
  };

  return (
    <div className={classes.root}>
      <FormControl component="fieldset" className={classes.formControl}>
        <FormLabel component="legend">{label}</FormLabel>
        <FormGroup>
          {R.map((select) => {
            return (
              <FormControlLabel
                control={
                  <Checkbox
                    id={`test-checkbox-${select}`.replace(/ /g, "-")}
                    checked={state[select]}
                    onChange={handleChange(select)}
                    value={select}
                  />
                }
                label={select}
              />
            );
          }, R.keys(state))}
        </FormGroup>
        {/* <FormHelperText>Be careful</FormHelperText> */}
      </FormControl>
    </div>
  );
};

CheckboxSelection.propTypes = {
  title: PropTypes.string,
  options: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CheckboxSelection);
