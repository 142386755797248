import React, { useContext } from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import MobileStepper from "@material-ui/core/MobileStepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import { CXContext } from "../context/Context";

const styles = theme => ({
  root: {
    margin: "20px auto",
    backgroundColor: "white"
  },
  stepIcon: {
    color: "#f70000 !important"
  }
});

const PhaseStepper = ({ classes }) => {
  const context = useContext(CXContext);
  const { countOfSteps, step } = context;

  return (
    <MobileStepper
      className={classes.root}
      steps={9}
      activeStep={step}
      variant="dots"
      position="static"
    >
      {countOfSteps.map(label => (
        <Step key={label}>
          <StepLabel
            StepIconProps={{
              classes: {
                root: classes.stepIcon,
                completed: classes.stepIcon,
                active: classes.stepIcon
              }
            }}
          >
            {label}
          </StepLabel>
        </Step>
      ))}
    </MobileStepper>
  );
};

PhaseStepper.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(PhaseStepper);
