import React from "react";
import { Route } from "react-router-dom";
import LoadingSpinner from "../LoadingSpinner";
import Login from "./AdminLogin";

import { AUTH_ACCEPTED, AUTH_REJECTED } from "../constants";

const AdminRoute = ({
  isAuthenticatedStatus,
  user,
  component: Component,
  t,
  ...rest
}) => {
  const renderContent = (props) => {
    switch (isAuthenticatedStatus) {
      case AUTH_ACCEPTED:
        return <Component t={t} user={user} {...props} />;
      case AUTH_REJECTED:
        return <Login t={t} />;
      default:
        return <LoadingSpinner />;
    }
  };
  return <Route {...rest} render={renderContent} />;
};
export default AdminRoute;
