import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { CXContext } from "./context/Context";
import PhaseButtons from "./PhaseButtons";
import { checkResponseType } from "./utils";
import * as R from "ramda";

const styles = {
  root: { marginBottom: 50 },
  card: {
    width: "100%",
    margin: 20,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 18,
  },
  pos: {
    marginBottom: 12,
  },
  description: {
    fontSize: 14,
    marginLeft: 50,
  },
};

const removeCoordinatesFromJson = (json) => {
  return Object.keys(json).reduce((acc, curr) => {
    let obj = json[curr];

    if (!!obj["Koordinaatit"] | !!obj["inRange"]) {
      const omittedObj = R.omit(["Koordinaatit", "inRange"], obj);
      acc[curr] = omittedObj;
      return acc;
    } else {
      acc[curr] = obj;
      return acc;
    }
  }, {});
};

const Yhteenveto = ({ classes, pageQuestion }) => {
  const context = useContext(CXContext);

  const { mainJson } = context;

  // const mainJson = {
  //   "Mikä vika?": {
  //     Syy: "Tekninen vika",
  //     "Kerro tarkemmin": "Auto sammui ajosta",
  //     "Syttyikö mittaristoon merkkivaloja?": "Kyllä",
  //     "Kerro omin sanoin enemmän": "Siihen syttyi mittarristo"
  //   },
  //   "Milloin tarvitset apua?": {
  //     "Milloin tarvitset apua?": "Ennakkotilaus",
  //     "Nouto aika": "2019-08-05T10:35:00.000Z"
  //   },
  //   "Auton sijainti": { Katuosoite: "Hämeenlinna, Finland" },
  //   "Tiedätkö minne auto viedää?": {
  //     "Tiedätkö minne auto viedään": "Kyllä",
  //     Katuosoite: "Autokeskuksentie, 33920 Pirkkala, Finland"
  //   },
  //   "Kuka maksaa?": {
  //     "Kuka maksaa?": "Vakuutusyhtiö",
  //     "Valitse vakuutusyhtiö": "Aktia"
  //   },
  //   "Ajoneuvon tiedot": {
  //     Rekisteritiedot: "ABC-123",
  //     "Auton merkki ja malli": "Lada Super",
  //     Lisätiedot: {
  //       Neliveto: true,
  //       "Automaatti vaihteisto": true,
  //       "Ajoneuvo on yrityksen nimissä": false
  //     },
  //     "Avainten saatavuus": "Heti"
  //   },
  //   "Yhteystietosi?": {
  //     Etunimi: "Markus",
  //     Sukunimi: "Anttila",
  //     Puhelinnumero: "0506486392",
  //     Sähköposti: "example@example.com"
  //   }
  // };

  const summaryJson = removeCoordinatesFromJson(mainJson);
  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        {Object.keys(summaryJson).map((header) => {
          return (
            <div className={classes.card}>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
              >
                {header}
              </Typography>
              {typeof summaryJson[header] === "string" ? (
                <Typography
                  className={classes.description}
                  color="textSecondary"
                  gutterBottom
                >
                  {`${summaryJson[header]}`}
                </Typography>
              ) : (
                Object.keys(summaryJson[header]).map((subHeader) => {
                  const subContent = summaryJson[header];

                  let response = checkResponseType(
                    summaryJson[header][subHeader]
                  );

                  return (
                    <Typography
                      className={classes.description}
                      color="textSecondary"
                      gutterBottom
                    >
                      {`${subHeader}: ${response}`}
                    </Typography>
                  );
                })
              )}
            </div>
          );
        })}
      </Grid>
      <PhaseButtons formValues={mainJson} onAction={(e) => e} />
    </Grid>
  );
};

Yhteenveto.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Yhteenveto);
