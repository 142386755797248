import React, { useContext, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import CardSelection from "./components/CardSelection";
import * as R from "ramda";
import { problemList } from "./constants";
import { CXContext } from "./context/Context";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";

const header = "Valitse mitä on tapahtunut";
const Aloitus = () => {
  const context = useContext(CXContext);
  const { stepForward, handlePath, onMergeToJson } = context;

  useEffect(() => {
    onMergeToJson({}, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleMoveForwardWithCorrectSelection = (key, title) => {
    handlePath(key);
    stepForward();
  };

  return (
    <Grid container spacing={16}>
      <Grid xs={12}>
        <Typography
          style={{
            fontSize: 24,
            padding: 10,
            color: "black",
            fontWeight: "bold"
          }}
          color="textSecondary"
          gutterBottom
        >
          {header}
        </Typography>
      </Grid>
      {R.map(selection => {
        return (
          <Grid md={4} sm={6} xs={12} style={{ padding: 5 }}>
            <CardSelection
              styles={{ height: "250px", paddingBottom: "20px" }}
              // icon={R.prop("image", selection)}
              onSelect={handleMoveForwardWithCorrectSelection}
              key={R.prop("key", selection)}
              id={R.prop("key", selection)}
              title={R.prop("title", selection)}
              description={R.prop("description", selection)}
            />
          </Grid>
        );
      }, problemList)}
    </Grid>
  );
};

export default Aloitus;
