import "date-fns";
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/date-fns";
import fiLocale from "date-fns/locale/fi";
import {
  MuiPickersUtilsProvider,
  TimePicker,
  DatePicker
} from "material-ui-pickers";

const styles = {
  grid: {
    width: "60%"
  }
};

const DateTimePicker = props => {
  const [selectedDate, handleDateChange] = useState(new Date());
  const { classes, onChange, initiaDate, id, label } = props;

  useEffect(() => {
    if (!!initiaDate) {
      handleDateTime(initiaDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDateTime = date => {
    handleDateChange(date);
    if (!!id) {
      onChange(id, date);
    } else {
      onChange(date);
    }
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={fiLocale}>
      <Grid container className={classes.grid} justify="space-around">
        <DatePicker
          data-test="date-picker"
          margin="normal"
          label="Valitse päivänmäärä"
          value={selectedDate}
          onChange={handleDateTime}
          cancelLabel="Peruuta"
        />
        <TimePicker
          data-test="time-picker"
          ampm={false}
          margin="normal"
          label="Valitse kellonaika"
          value={selectedDate}
          onChange={handleDateTime}
          cancelLabel="Peruuta"
        />
      </Grid>
    </MuiPickersUtilsProvider>
  );
};

DateTimePicker.propTypes = {
  classes: PropTypes.object.isRequired,
  initiaDate: PropTypes.object,
  id: PropTypes.string,
  label: PropTypes.string
};
DateTimePicker.defaultProps = {
  initiaDate: null,
  id: null,
  label: null
};

export default withStyles(styles)(DateTimePicker);
