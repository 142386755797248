import React, { useState, useEffect } from "react";
import Select from "react-select";
import Grid from "@material-ui/core/Grid";
import FormLabel from "@material-ui/core/FormLabel";
import { isEmpty } from "ramda";

const FilterSelect = (props) => {
  const { onChange, value, options, label, t } = props;
  const [state, setState] = useState({ selectedOption: null });

  useEffect(() => {
    handleChange(value);
  }, []);

  const handleChange = (selectedOption) => {
    if (isEmpty(selectedOption)) {
      selectedOption = undefined;
    }
    setState(
      (prevProps) => ({ ...prevProps, selectedOption }),
      onChange(selectedOption)
    );
  };
  return (
    <Grid item xs={12} sm={4} md={3} lg={2} style={{ padding: 3 }}>
      <FormLabel component="legend">{t(label)}</FormLabel>
      <Select
        placeholder={t("filterSelect")}
        isClearable={true}
        isMulti={true}
        value={state.selectedOption}
        onChange={handleChange}
        options={options}
        closeMenuOnSelect={false}
      />
    </Grid>
  );
};

FilterSelect.defaultProps = {
  onChange: (e) => e,
};

export default FilterSelect;
