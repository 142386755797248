import React, { useEffect, useContext, useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Modal from "@material-ui/core/Modal";
import { CXContext } from "../context/Context";
import Button from "@material-ui/core/Button";
import CheckCircle from "@material-ui/icons/CheckCircle";

const styles = theme => ({
  paper: {
    fontSize: 14,
    fontWeight: "normal",
    fontFamily: "monospace",
    position: "absolute",
    left: "50%",
    top: "40%",
    transform: "translate(-50%, -30%)",
    width: "45%",
    height: "50%",
    backgroundColor: "white",
    textAlign: "center",
    padding: 30
  },
  buttons: {
    zIndex: 9999,
    width: "100%",
    maxHeight: 100,
    display: "flex",
    position: "fixed",
    bottom: 0,
    backgroundColor: "white",
    alignItems: "center",
    marginTop: 5
  },
  button: {
    marginTop: theme.spacing.unit * 3,
    marginLeft: theme.spacing.unit,
    boxShadow: "none"
  },
  buttonContainer: {
    margin: "50px 0",
    width: "100%"
  },
  icon: {
    color: "green",
    fontSize: 60
  }
});

const StatusModal = props => {
  const { classes } = props;
  const context = useContext(CXContext);
  const { formSubmitted, formError, handleProcessComplete } = context;

  // getModalStyle is not a pure function, we roll the style only on the first render
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!!formError || !!formSubmitted) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [formSubmitted, formError]);

  const handleClose = () => {
    setOpen(false);
    handleProcessComplete();
  };

  return (
    <div>
      <Modal
        disableAutoFocus
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={handleClose}
      >
        <div className={classes.paper}>
          <CheckCircle className={classes.icon} />
          <p style={{ fontSize: 18, fontWeight: "bold" }}>
            Tilaukesi on vastaanotettu
          </p>
          <p>
            Asiakaspalvelijamme arvioi kanssasi tilanteen, ja sen tarvitaanko
            hinausta vai tiepalvelua
          </p>
          <p>
            Odottaessasi hinausauton saapumista, huolehdi omasta sekä ympäröivän
            liikenteen turvallisuudesta Aseta varoituskolmio oikeaoppisen
            etäisyyden päähän hinausta odottavasta ajoneuvostasi
          </p>
          <Button
            id="phase-button-submit"
            variant="contained"
            color="primary"
            onClick={() => {
              handleClose();
            }}
            className={classes.button}
          >
            Ok
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default withStyles(styles)(StatusModal);
