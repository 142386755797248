import React, { useEffect, useReducer } from "react";
import ReactDOM from "react-dom";
import ReactGA from "react-ga";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import { pathOr } from "ramda";

import AdminOrderForm from "./Admin/AdminOrderForm";
import AdminOrderList from "./Admin/AdminOrderList";
import AdminPartnerList from "./Admin/AdminPartnerList";
import App from "./App";
import Login from "./Admin/AdminLogin";
import PageNotFound from "./Admin/PageNotFound";
import AdminRoute from "./Admin/AdminRoute";
import ClientRoute from "./ClientRoute";

import AdminOrderConfirmationDialog from "./Admin/AdminOrderConfirmationDialog";
import AdminOrderErrorDialog from "./Admin/AdminOrderErrorDialog";
import "./i18n";
import { useTranslation } from "react-i18next";

import * as serviceWorker from "./serviceWorker";
import { createGenerateClassName, JssProvider } from "react-jss";

import { verifyUserAuthenticated, GAPageViewAction } from "./utils";

import {
  PATH_PAGE_NOT_FOUND,
  PATH_LOGIN,
  PATH_ROOT,
  PATH_ADMIN_ORDER_FORM,
  PATH_ADMIN_ORDER_LIST,
  PATH_ADMIN_PARTNER_LIST,
  PATH_ADMIN_ORDER_CONFIRMATION_MESSAGE,
  PATH_ADMIN_ORDER_ERROR_MESSAGE,
  GA_ROOT_PATH,
  AUTH_WAITING,
  AUTH_ACCEPTED,
  AUTH_REJECTED,
} from "./constants";

import "./index.css";

// //Setup Google Analytics
// const trackingId = "GTM-KWT493D";
// ReactGA.initialize(trackingId, {
//   debug: false,
//   titleCase: false
// }); // Add Google analytics

// ReactGA.ga("set", "checkProtocolTask", null);
// ReactGA.pageview(`/${GA_ROOT_PATH}`);

const generateClassName = createGenerateClassName();

const initialState = { status: AUTH_REJECTED };

function reducer(state, action) {
  switch (action.type) {
    case AUTH_ACCEPTED:
      return { status: AUTH_ACCEPTED, user: action.payload };
    case AUTH_REJECTED:
      return { status: AUTH_REJECTED };
    default:
      return { status: AUTH_WAITING };
  }
}

const AppRouter = () => {
  const { t, i18n } = useTranslation();

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    window.dataLayer = window.dataLayer || []; // initiate datalayer
    GAPageViewAction();
    console.log("Authorizing user response... ");
    dispatch({ type: AUTH_WAITING });
    verifyUserAuthenticated().then((response) => {
      console.log("Authorizing response: ", response);
      const isAuthenticated = pathOr(
        null,
        ["data", "isAuthenticated"],
        response
      );

      const user = pathOr(null, ["data", "user", "displayName"], response);

      !!isAuthenticated
        ? dispatch({ type: AUTH_ACCEPTED, payload: user })
        : dispatch({ type: AUTH_REJECTED });
    });
  }, []);

  return (
    <Router>
      <i18n>
        <ClientRoute t={t} path={PATH_ROOT} exact component={App} />
        <ClientRoute t={t} path={PATH_LOGIN} component={Login} />
        <Route t={t} path={PATH_PAGE_NOT_FOUND} component={PageNotFound} />
        <AdminRoute
          isAuthenticatedStatus={state.status}
          user={state.user}
          path={PATH_ADMIN_ORDER_FORM}
          t={t}
          component={AdminOrderForm}
        />
        <AdminRoute
          isAuthenticatedStatus={state.status}
          user={state.user}
          path={PATH_ADMIN_ORDER_LIST}
          t={t}
          component={AdminOrderList}
        />
        <AdminRoute
          isAuthenticatedStatus={state.status}
          user={state.user}
          path={PATH_ADMIN_ORDER_CONFIRMATION_MESSAGE}
          t={t}
          component={AdminOrderConfirmationDialog}
        />
        <AdminRoute
          isAuthenticatedStatus={state.status}
          user={state.user}
          path={PATH_ADMIN_ORDER_ERROR_MESSAGE}
          t={t}
          component={AdminOrderErrorDialog}
        />
        <AdminRoute
          isAuthenticatedStatus={state.status}
          user={state.user}
          path={PATH_ADMIN_PARTNER_LIST}
          t={t}
          component={AdminPartnerList}
        />
      </i18n>
    </Router>
  );
};

/*==================*/

ReactDOM.render(
  <JssProvider generateClassName={generateClassName}>
    <AppRouter />
  </JssProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
